<div class="msg-list-single"  [ngClass]="status ? 'show' : ''">
  <div class="msg-list-single-body">
    <div class="msg">
      <div class="avatar">
        <user-avatar [isOnline]="msgThread.latestMsgFrom.userOnline" [avatarImg]="msgThread.threadtoUserImg"></user-avatar>
      </div>
      <div class="msg-body">
        <h4 class="user-name">{{msgThread.threadTo}}</h4>
        <p class="last-msg " [class.unread]='msgThread.latestMsgUnreadStatus'>{{msgThread.latestMsgFrom.username ? msgThread.latestMsgFrom.username.split(' ')[0]  : msgThread.threadTo.split(' ')[0] }}: {{msgThread.latestMsg}}</p>
      </div>
    </div>
    <div class="msg-time">
      <p> Dec 12</p>
    </div>
  </div>


</div>
