import { UIModule } from './../../../../shared/ui/ui.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesComponent } from '../messages.component';
import { MessageListSingleComponent } from '../message-list-single/message-list-single.component';
import { MessageInputComponent } from '../message-input/message-input.component';
import { MessageThreadComponent } from '../message-thread/message-thread.component';
import { DashboardsModule } from '../../dashboards.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SmoothScrollModule } from 'ngx-scrollbar/smooth-scroll';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    MessagesComponent,
    MessageListSingleComponent,
    MessageInputComponent,
    MessageThreadComponent
  ],
  imports: [
    CommonModule,
    DashboardsModule,
    UIModule,
    NgbDropdownModule,
    NgScrollbarModule,
    SmoothScrollModule,
    FormsModule
  ],
  exports: [
    MessageListSingleComponent,
    MessageInputComponent,
    MessageThreadComponent
  ]
})
export class MsgSharedModule { }
