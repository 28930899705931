import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter, SimpleChanges } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { msglist, msglist2, msglist3, msglist4, msglist5, msglist6, msglist7 } from 'src/app/pages/dashboards/messages/msgData';
import { msgList } from 'src/app/pages/dashboards/messages/msgmodel';

@Component({
  selector: 'message-single-popup',
  templateUrl: './message-single-popup.component.html',
  styleUrls: ['./message-single-popup.component.scss']
})
export class MessageSinglePopupComponent implements OnInit {

  messages: msgList[] = [msglist, msglist2, msglist3, msglist4, msglist5, msglist6, msglist7];
  @Input() currentThread: msgList;
  @ViewChild('scrollableMsg') scrollableMsg: NgScrollbar;
  @ViewChild('msgList') msgListDiv: ElementRef;
  @ViewChild('msgSingle') msgSingle: ElementRef;
  @Output() msgCurrent = new EventEmitter<Boolean>();
  @Input() minimized: boolean = false

  draft: String = ''

  constructor() { }
  ngOnChanges(changes: SimpleChanges) {
    this.maxMsg();
  }

  ngOnInit(): void {
    this.minimized = false
  }

  ngAfterViewInit(): void {
    this.scrollableMsg.scrollTo({ bottom: 0, duration: 0 })
  }


  minimizeMsg() {
    if (this.minimized) {
      this.minimized = false
      this.scrollableMsg.scrollTo({ bottom: 0, duration: 0 })
    }
    else {
      this.minimized = true
    }
  }

  maxMsg() {
    if (this.minimized)
      this.minimized = false
  }
  msgClose() {
    this.msgCurrent.emit(false)
  }
  diplayList() {
    let viewportWidth = Math.round(window.innerWidth);
    if (viewportWidth < 768) {
      this.msgSingle.nativeElement.style.display = 'none'
      this.msgListDiv.nativeElement.style.display = 'block'
    }
  }
}
