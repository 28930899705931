import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'comment-area',
  templateUrl: './comment-area.component.html',
  styleUrls: ['./comment-area.component.scss']
})
export class CommentAreaComponent implements OnInit {
  loadMore: number = 1
  list: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  constructor() { }

  ngOnInit(): void {
  }

}
