<div class="container-fluid">
  <!-- start page title -->
  <div class="row">
    <div class="col-xl">

      <app-search-box searchTitle="650 Connections " (selectedUserType)="filteruser($event)" [isSelector]="true"></app-search-box>

      <div class="row">
        <div class="col-md-6">
          <app-connection-card name="Ananya Mathew" position="NY state Counil"
            avatarImg="assets/images/home/avatar-2.png" userType="Recruiter"></app-connection-card>
        </div>
        <div class="col-md-6">
          <app-connection-card name="Jenny Alex" position="NY state Counil"
            avatarImg="assets/images/home/avatar-3.png" userType="Therapist"></app-connection-card>
        </div>
        <div class="col-md-6">
          <app-connection-card name="Jenny Alex" position="NY state Counil"
            avatarImg="assets/images/home/avatar-1.png" userType="Recruiter"></app-connection-card>
        </div>
        <div class="col-md-6">
          <app-connection-card name="Ananya Mathew" position="NY state Counil"
            avatarImg="assets/images/home/avatar-4.png" userType="Therapist"></app-connection-card>
        </div>
        <div class="col-md-6">
          <app-connection-card name="Ananya Mathew" position="NY state Counil"
            avatarImg="assets/images/home/avatar-4.png" userType="Recruiter"></app-connection-card>
        </div>
        <div class="col-md-6">
          <app-connection-card name="Jenny Alex" position="NY state Counil"
            avatarImg="assets/images/home/user.png" userType="Therapist"></app-connection-card>
        </div>
        <div class="col-md-6">
          <app-connection-card name="Jenny Alex" position="NY state Counil"
            avatarImg="assets/images/home/avatar-1.png" userType="Recruiter"></app-connection-card>
        </div>
        <div class="col-md-6">
          <app-connection-card name="Ananya Mathew" position="NY state Counil"
            avatarImg="assets/images/home/avatar-2.png" userType="Therapist"></app-connection-card>
        </div>
        <div class="col-md-6">
          <app-connection-card></app-connection-card>
        </div>
        <div class="col-md-6">
          <app-connection-card></app-connection-card>
        </div>
        <div class="col-md-6">
          <app-connection-card></app-connection-card>
        </div>
        <div class="col-md-6">
          <app-connection-card></app-connection-card>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button class="view-more-btn">
            View More
          </button>
        </div>
      </div>

      <div class="recommendations">
        <h4>Grow your network today:</h4>

        <div class="row">
          <div class="col-md-6">
            <app-connection-card name="Ananya Mathew" postion="NY state Counil"
              avatarImg="assets/images/home/avatar-2.png" [connected]=false></app-connection-card>
          </div>
          <div class="col-md-6">
            <app-connection-card name="Jenny Alex" postion="NY state Counil"
              avatarImg="assets/images/home/avatar-3.png" [connected]=false></app-connection-card>
          </div>
          <div class="col-md-6">
            <app-connection-card name="Jenny Alex" postion="NY state Counil"
              avatarImg="assets/images/home/avatar-1.png" [connected]=false></app-connection-card>
          </div>
          <div class="col-md-6">
            <app-connection-card name="Ananya Mathew" postion="NY state Counil"
              avatarImg="assets/images/home/avatar-4.png" [connected]=false></app-connection-card>
          </div>
          <div class="col-md-6">
            <app-connection-card name="Ananya Mathew" postion="NY state Counil"
              avatarImg="assets/images/home/avatar-4.png" [connected]=false></app-connection-card>
          </div>
          <div class="col-md-6">
            <app-connection-card name="Jenny Alex" postion="NY state Counil"
              avatarImg="assets/images/home/user.png" [connected]=false></app-connection-card>
          </div>
          <div class="col-md-6">
            <app-connection-card name="Jenny Alex" postion="NY state Counil"
              avatarImg="assets/images/home/avatar-1.png" [connected]=false></app-connection-card>
          </div>
          <div class="col-md-6">
            <app-connection-card name="Ananya Mathew" postion="NY state Counil"
              avatarImg="assets/images/home/avatar-2.png" [connected]=false></app-connection-card>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button class="view-more-btn">
            View More
          </button>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-7  col-md-6 right-sidebar">
      <right-sidebar></right-sidebar>
    </div>
  </div>
  <!-- end page title -->
</div> <!-- container-fluid -->