<div class="card connection-card">
  <div class="card-body">
    <div class="media">
      <div class="avatar">
        <user-avatar [avatarImg]="avatarImg"></user-avatar>
      </div>
      <div class="media-body">
        <h5 class="name"><a [routerLink]="userLink()">{{name}}</a></h5>
        <div class="position">{{userType}} {{position}} </div>
      </div>

      <div *ngIf="isInactive" class="duration"> 
        <span class="from">12.02.2018</span> -  <span class="to">14.10.2021</span>
      </div>


      <button *ngIf="!isInactive" (click)="msgBtnClick()" class="btn ms-2">{{connected? 'Message': conncetSts}}</button>

      <div class="dropdown ms-3" ngbDropdown>
        <a class="text-muted dropdown-toggle" href="javascript: void(0);" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
          <i class="mdi mdi-dots-horizontal font-size-24"></i>
        </a>
        <div class="dropdown-menu  dropdown-menu-end" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript: void(0);" routerLink="/profile/inactive-recruiter-profile-in-company">Inactive Profile</a>
          <a class="dropdown-item" href="javascript: void(0);">Action</a>
          <a class="dropdown-item" href="javascript: void(0);">Another action</a>
          <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
        </div>
      </div>

    </div>
  </div>
</div>