<div class="card post-card">
  <div *ngIf="isCommented" class="card-header">
    Sameera Ali <span class="commented">commented on this</span>
    <div class="float-end dropdown ms-2" ngbDropdown>
      <a class="text-muted dropdown-toggle" href="javascript: void(0);" role="button" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
        <i class="mdi mdi-dots-horizontal font-size-24"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
        <a class="dropdown-item" href="javascript: void(0);">Action</a>
        <a class="dropdown-item" href="javascript: void(0);">Another action</a>
        <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="post-header">
      <div class="media">
        <div class="user-img">
          <user-avatar avatarImg="assets/images/home/avatar-1.png" avatarVerifiedGreen="true"></user-avatar>
        </div>
        <div class="media-body">
          <div class="user-title"><a href="javascript:void(0)" routerLink="/connections">Fathima Hakheem</a>
            <div class="badge blue"><img src="assets/images/icons/liri-badge.svg" alt=""></div>
          </div>
          <div class="user-designation">Psychologist</div>
          <div class="post-time">5d</div>
        </div>
      </div>
    </div>
    <div class="post-body">
      <div class="text-area" [class.show]="show">
        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
          aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae perspiciatis unde omnis iste
          natus error sitSed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
          laudantium,
          totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae perspiciatis unde
          omnis iste natus error sit<a href="javascript:void(0)" (click)="show = !show">{{ show ? '': '...See more' }}</a></p>
      </div>
      <div class="tag-area">
        <a href="#" class="tag">#psychiatry </a>
        <a href="#" class="tag">#psychology </a>
        <a href="#" class="tag">#caparison </a>
      </div>
      <div *ngIf="isPostImg" class="post-area">
        <img class="post-img" src="assets/images/home/post.jpg" alt="">
      </div>
      <div *ngIf="isPostVideo" class="post-area video">
        <video width="100%" height="auto" controls controlsList="nodownload">
          <source src="assets/video/video.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
      <postcard-share *ngIf="isShared" [isPostImg]="true" [isPostVideo]="false"></postcard-share>
      <div class="post-reactions">
        <a href="javascript: void(0);" class="reaction"><img src="assets/images/icons/like-filled.svg" alt=""></a>
        <a href="javascript: void(0);" class="reaction"><img src="assets/images/icons/clap.svg" alt=""></a>
        <a href="javascript: void(0);" class="reaction"><img src="assets/images/icons/love.svg" alt=""></a>
        <a href="javascript: void(0);">John Jacob</a> and <a href="#">{{likes}} others</a>
        <a href="javascript: void(0);" (click)="IsCommentArea= !IsCommentArea">
          <div class="float-end ms-2">3 Comments</div>
        </a>
      </div>
    </div>
    <div class="post-footer">
      <div class="row like-row">
        <div class="col">
          <div class="alignment">
            <a href="javascript: void(0);" class="{{ isLiked ? 'liked' :''}}" (click)="liked()">
              <!-- <img class="liked" src="assets/images/icons/like.svg" alt=""> -->
              <svg class="{{ isLiked ? 'liked' :''}}" fill="#6d6d6d" id="noun_Approve_3593315"
                xmlns="http://www.w3.org/2000/svg" width="16.242" height="16.19" viewBox="0 0 16.242 16.19">
                <path id="Path_3920" data-name="Path 3920"
                  d="M38.826,24.658l-.024,0a.851.851,0,0,0-.646.325,1.236,1.236,0,0,0-.27.716.966.966,0,0,0,.208.678.86.86,0,0,0,.6.287c.391.008.8.013,1.259.013a1.858,1.858,0,0,0,1.929-1.173,1.057,1.057,0,0,0-.011-.735c-.066-.131-.077-.115-.214-.115H38.826Zm-.151-2.3-.386,0a.959.959,0,0,0-.918.939.8.8,0,0,0,.2.6.992.992,0,0,0,.771.276h3.532a.231.231,0,0,0,.214-.081,1.112,1.112,0,0,0,.291-.807,1.353,1.353,0,0,0-.225-.839c-.07-.1-.1-.089-.2-.089H38.675Zm-.056-2.346a.971.971,0,0,0-.916.962.846.846,0,0,0,.2.619.982.982,0,0,0,.771.279h3.562a.347.347,0,0,0,.3-.117,1.193,1.193,0,0,0,.3-.788,1.175,1.175,0,0,0-.375-.851c-.088-.112-.143-.1-.274-.1H38.619Zm.05-2.962c-.406,0-.755.7-.783,1.256a1.412,1.412,0,0,0,.22.862.849.849,0,0,0,.754.356h3.325a.28.28,0,0,0,.212-.086,1.2,1.2,0,0,0,.459-.992,1.421,1.421,0,0,0-1.368-1.363l-2.745-.028C38.715,17.049,38.7,17.049,38.669,17.047Zm-1.682-6.553a.636.636,0,0,0-.137,0,.9.9,0,0,0-.774.762c-.994,3.347-2.227,4.565-4.226,6.565a.886.886,0,0,0-.26.5v7.054c0,.227.216.247.425.247a8.736,8.736,0,0,1,2.161.614,19,19,0,0,0,3.5.4,1.462,1.462,0,0,1-.278-.959,1.7,1.7,0,0,1,.38-1c.023-.027.048-.052.072-.078a1.342,1.342,0,0,1-.637-.367,1.293,1.293,0,0,1-.33-.958,1.507,1.507,0,0,1,.78-1.241,1.113,1.113,0,0,1-.12-.112,1.332,1.332,0,0,1-.33-.974c0-.006,0-.012,0-.018a3.369,3.369,0,0,1-2.148.727c-.187,0-.246-.349,0-.349a2.717,2.717,0,0,0,2.619-1.887,1.559,1.559,0,0,1-.29-1.133c.17-.915.534-2,.6-2.588a8.22,8.22,0,0,1,.507-1.818,2.617,2.617,0,0,0-.6-2.955A1.548,1.548,0,0,0,36.988,10.494Z"
                  transform="translate(-26.615 -10.491)" />
                <path id="Path_3921" data-name="Path 3921"
                  d="M14.578,42.383a1.2,1.2,0,0,0-1.194-1.194H11.578a1.2,1.2,0,0,0-1.192,1.194V48.39a1.194,1.194,0,0,0,1.192,1.192h1.806a1.2,1.2,0,0,0,1.194-1.192Z"
                  transform="translate(-10.386 -33.986)" />
              </svg>
              <span>Like</span>
              <div class="post-reactions">
                <a href="javascript: void(0);"><img src="assets/images/icons/like-filled.svg" alt=""></a>
                <a href="javascript: void(0);"><img src="assets/images/icons/clap.svg" alt=""></a>
                <a href="javascript: void(0);" class="care"><img src="assets/images/icons/icon-care.svg" alt=""></a>
                <a href="javascript: void(0);"><img src="assets/images/icons/love.svg" alt=""></a>
                <a href="javascript: void(0);"><img src="assets/images/icons/icon-idea.svg" alt=""></a>
              </div>
            </a>
          </div>
        </div>
        <div class="col">
          <div class="alignment">
            <a href="javascript: void(0);" (click)="IsCommentArea = ! IsCommentArea"><img
                src="assets/images/icons/comment.svg" alt=""><span>Comment</span></a>
          </div>
        </div>
        <div class="col">
          <div class="alignment">
            <a href="javascript: void(0);"><img src="assets/images/icons/share.svg" alt=""><span>Share</span></a>
          </div>
        </div>
        <div class="col">
          <div class="alignment">
            <a href="javascript: void(0);"><img src="assets/images/icons/send.svg" alt=""><span>Send</span></a>
          </div>
        </div>
        <div class="col">
          <div class="alignment">
            <a href="javascript: void(0);"><img src="assets/images/icons/reward.svg" alt=""><span>Reward</span></a>
          </div>
        </div>
      </div>
      <div class="comment-area " *ngIf="IsCommentArea">
        <add-comment></add-comment>
        <div class="select" ngbDropdown>
          <a class="text-muted dropdown-toggle" href="javascript: void(0);" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
            Most Relevant <img src="assets/images/icons/dropdown-icon.svg" alt="">
          </a>
          <div class="dropdown-menu  " ngbDropdownMenu>
            <a class="dropdown-item" href="javascript: void(0);">Action</a>
            <a class="dropdown-item" href="javascript: void(0);">Another action</a>
            <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
          </div>
        </div>
        <single-comment></single-comment>
        <single-comment></single-comment>
        <a href="  javascript: void(0);" class="fw-500 text-muted">Load more comments</a>
      </div>
    </div>
  </div>
</div>