<div class="message-list card">
  <div class="list-header card-body">
    <div class=" message-toggle-left" (click)="msgListActive = !msgListActive">
      <div class="avatar">
        <user-avatar [isOnline]="true"></user-avatar>
      </div>
      <p class="msgng">
        Messaging
      </p>
    </div>
    <div class="message-toggle-right">
      <div class="dropdown hover-bg" ngbDropdown>
        <a class="text-muted dropdown-toggle" href="javascript: void(0);" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
          <i class="mdi mdi-dots-horizontal fz-24"></i>
        </a>
        <div class="dropdown-menu  dropdown-menu-top-end" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript: void(0);">Action</a>
          <a class="dropdown-item" href="javascript: void(0);">Another action</a>
          <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
        </div>
      </div>

      <div class="add-msg hover-bg">
        <svg id="Group_1948" data-name="Group 1948" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.752 17.007">
          <path id="Path_3899" data-name="Path 3899"
            d="M18.248,26.144V27.2a.869.869,0,0,1-.87.87H5.109a.869.869,0,0,1-.87-.87V17.652H17.515l2.4-2.838A2.625,2.625,0,0,0,17.378,12.8H5.109A2.638,2.638,0,0,0,2.5,15.409V27.2a2.608,2.608,0,0,0,2.609,2.609H17.378A2.608,2.608,0,0,0,19.987,27.2V24.451l-.618.71A3.825,3.825,0,0,1,18.248,26.144Z"
            transform="translate(-2.5 -12.8)" fill="#707070" />
          <path id="Path_3900" data-name="Path 3900"
            d="M28.443,45.73l.8-.938a1.077,1.077,0,0,0-.366-.092H21.37a.87.87,0,1,0,0,1.74h6.615A2.521,2.521,0,0,1,28.443,45.73Z"
            transform="translate(-16.38 -37.398)" fill="#707070" />
          <path id="Path_3901" data-name="Path 3901"
            d="M20.5,61.97a.869.869,0,0,0,.87.87h5.562a1.493,1.493,0,0,1,.046-.549l.3-1.19H21.37A.869.869,0,0,0,20.5,61.97Z"
            transform="translate(-16.38 -50.045)" fill="#707070" />
          <path id="Path_3902" data-name="Path 3902"
            d="M55.746,58.094l1.877-.8a3.63,3.63,0,0,0,.984-.687L56.112,54.5a3.093,3.093,0,0,0-.5,1.076l-.481,1.968A.449.449,0,0,0,55.746,58.094Z"
            transform="translate(-43.066 -44.955)" fill="#707070" />
          <path id="Path_3903" data-name="Path 3903"
            d="M70.916,23.04l-.938-.8a1.051,1.051,0,0,0-1.488.114L63.5,28.282l2.564,2.152,4.99-5.883A1.1,1.1,0,0,0,70.916,23.04Z"
            transform="translate(-49.538 -19.881)" fill="#707070" />
        </svg>

      </div>

      <div class="open-msg hover-bg" [class.active]="msgListActive" (click)="msgListActive = !msgListActive">
        <svg xmlns="http://www.w3.org/2000/svg" width="20.207" height="12.696" viewBox="0 0 20.207 12.696">
          <path id="Path_3941" data-name="Path 3941"
            d="M5857.251,7232.562a1,1,0,0,1-.772-1.635l8.786-10.7a1,1,0,0,1,.756-.365h.017a1,1,0,0,1,.75.339l9.422,10.7a1,1,0,1,1-1.5,1.322l-8.645-9.814-8.04,9.788A1,1,0,0,1,5857.251,7232.562Z"
            transform="translate(-5856.252 -7219.866)" fill="#707070" />
        </svg>

      </div>
    </div>


  </div>

</div>
<div class="msg-list-pop">
  <message-list-popup (currentThread)="openMsg($event); unMinimize()" *ngIf="msgListActive"></message-list-popup>
</div>
<div class="msg-single-pop">
  <message-single-popup *ngIf="msgCurrent" (msgCurrent)="closeMsg(msgCurrent)" [minimized]=minimized
    [currentThread]="currentThread"></message-single-popup>
</div>
