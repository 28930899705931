<div class="comment-area " *ngIf="1">
  <add-comment></add-comment>
  <div class="select" ngbDropdown>
    <a class="text-muted dropdown-toggle" href="javascript: void(0);" role="button" data-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
      Most Relevant <img src="assets/images/icons/dropdown-icon.svg" alt="">
    </a>
    <div class="dropdown-menu  " ngbDropdownMenu>
      <a class="dropdown-item" href="javascript: void(0);">Action</a>
      <a class="dropdown-item" href="javascript: void(0);">Another action</a>
      <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
    </div>
  </div>
  <ng-container *ngFor="let item of list.slice(0,loadMore)">
    <single-comment></single-comment>
  </ng-container>

  <a (click)="loadMore=loadMore+1" class="fw-500 text-muted">Load more comments</a>
</div>
