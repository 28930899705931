import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'Feed',
    icon: 'assets/images/icons/feed.svg',
    link: 'feed'

  }, {
    id: 2,
    label: 'Connections',
    icon: 'assets/images/icons/connection.svg',
    link: 'connections'

  }, {
    id: 3,
    label: 'Jobs',
    icon: 'assets/images/icons/jobs.svg',
    link: 'jobs'

  }, {
    id: 4,
    label: 'Events',
    icon: 'assets/images/icons/events.svg',
    link: 'events'

  }, {
    id: 5,
    label: 'My Trainings',
    icon: 'assets/images/icons/training.svg',
    link: 'my-trainings'

  }, {
    id: 6,
    label: 'My Page',
    icon: 'assets/images/icons/page.svg',
    link: 'my-pages'

  }, {
    id: 7,
    label: 'Messages',
    icon: 'assets/images/icons/messages.svg',
    link: 'messages'

  }, {
    id: 8,
    label: 'Coins',
    icon: 'assets/images/icons/coin.svg',
    link: 'coins'

  },

];
export const RECRUITERMENU: MenuItem[] = [
  {
    id: 1,
    label: 'Feed',
    icon: 'assets/images/icons/feed.svg',
    link: 'feed'

  }, {
    id: 2,
    label: 'Connections',
    icon: 'assets/images/icons/connection.svg',
    link: 'connections'

  }, {
    id: 3,
    label: 'Job Postings',
    icon: 'assets/images/icons/jobs.svg',
    link: 'job-postings'

  }, {
    id: 4,
    label: 'Bookmarks',
    icon: 'assets/images/icons/bookmark.svg',
    link: 'bookmarks'

  }, {
    id: 5,
    label: 'Purchased profile',
    icon: 'assets/images/icons/profile-purchased-blue.svg',
    link: 'purchased'

  }, {
    id: 6,
    label: 'Messages',
    icon: 'assets/images/icons/messages.svg',
    link: 'messages'

  }, {
    id: 7,
    label: 'Coins',
    icon: 'assets/images/icons/coin.svg',
    link: 'coins'

  },

];
export const COMPANYMENU: MenuItem[] = [
  {
    id: 1,
    label: 'Post News',
    icon: 'assets/images/icons/feed.svg',
    link: 'post-news'

  }, {
    id: 2,
    label: 'Active Recruiters',
    icon: 'assets/images/icons/connection.svg',
    link: 'active-recruiters'

  }, {
    id: 3,
    label: 'Requests',
    icon: 'assets/images/icons/requests.svg',
    link: 'requests'

  }, {
    id: 4,
    label: 'Inactive Recruiters',
    icon: 'assets/images/icons/jobs.svg',
    link: 'inactive-recruiters'

  } 

];

