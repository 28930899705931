import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss']
})
export class RightSidebarComponent implements OnInit {

  @Input() updates: any[] = []
  @Input() isCompany: boolean = false  
  constructor() { }

  ngOnInit(): void {
  }

  checkTimeDiff(d: Date) {
    var current = new Date()
    var offset = current.getTimezoneOffset()
    var timeZ = d.getTimezoneOffset()
    var timeZoneDiff = timeZ - offset
    if (timeZoneDiff === 0) {
      var t = +(current.getTime()) - +(d.getTime())
      // console.log(t)
    }
    return (t.toString + ' ago')
  }

}
