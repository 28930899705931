import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {
  @Input() avatarImg: string = "assets/images/home/avatar.png"
  @Input() avatarVerified: boolean = false
  @Input() avatarVerifiedGreen: boolean = false
  @Input() avatarOpentowork: boolean = false
  @Input() avatarMentor: boolean = false
  @Input() avatarHiring: boolean = false
  @Input() isOnline: boolean = false
  @Input() isBusy: boolean = false
  @Input() isOffline: boolean = false
  constructor() { }

  ngOnInit(): void {
    if (typeof this.avatarImg == 'undefined' || this.avatarImg == null) {
      this.avatarImg = "assets/images/home/avatar.png"
    }
  }

}
