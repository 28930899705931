<div class="container-fluid">
  <!-- start page title -->
  <div class="row">
    <div class="col-xl">

      <div class="card title-card">
        <div class="card-body title-card-body">
          <h4 class="title">Chat Room</h4>
        </div>
      </div>
      <div class="row">
        <div #msgList class="col-md-5 msg-list">
          <div class="card">
            <div class="msg-list-header">
              <div class="search">
                <form action="">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Search">
                    <span class="icon search"><img src="assets/images/icons/search-icon.svg" alt=""></span>
                    <span class="icon filter"><img src="assets/images/icons/filter-icon.svg" alt=""></span>
                  </div>
                </form>
              </div>
            </div>
            <div class="card-body msg-list-body">

              <ng-scrollbar smoothScroll #scrollable track="all" pointerEventsMethod="scrollbar">
                <ng-container *ngFor="let msgThread of messages; let i = index; let f = first">
                  <message-list-single-message [status]="selectedIndex === i " [msgThread]="msgThread"
                    (click)="openMsg(i,msgThread)">
                  </message-list-single-message>
                </ng-container>
              </ng-scrollbar>

            </div>
          </div>
        </div>
        <div #msgSingle class="col-md-7 msg-single">
          <div class="card">
            <div class="msg-single-header">
              <div class="thread-info">
                <div class="go-back" (click)="diplayList()">
                  <svg id="Backward_arrow" data-name="Backward arrow" xmlns="http://www.w3.org/2000/svg" width="16"
                    height="16" viewBox="0 0 16 16">
                    <path id="Path_10" data-name="Path 10"
                      d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z"
                      transform="translate(16 16) rotate(180)" fill="#707070" />
                  </svg>
                </div>
                <div class="avatar">

                  <user-avatar [isOnline]="true" avatarImg="{{currentThread.threadtoUserImg}}"></user-avatar>
                </div>
                <h4 class="user-name">{{currentThread.threadTo}}</h4>
              </div>

              <div class="dropdown ms-3" ngbDropdown>
                <a class="text-muted dropdown-toggle" href="javascript: void(0);" role="button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                  <i class="mdi mdi-dots-horizontal font-size-24"></i>
                </a>
                <div class="dropdown-menu  dropdown-menu-end" ngbDropdownMenu>
                  <a class="dropdown-item" href="javascript: void(0);">Action</a>
                  <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                  <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                </div>
              </div>
            </div>
            <div class="card-body">
              <ng-scrollbar #scrollableMsg track="all" pointerEventsMethod="scrollbar" style="height: 100%">
                <div class="msg-single-body">
                  <message-thread [msgThread]="currentThread"></message-thread>
                </div>
              </ng-scrollbar>


              <div class="msg-single-footer">
                <message-input></message-input>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="col-xl-3 col-lg-7  col-md-6 right-sidebar">
      <right-sidebar></right-sidebar>

    </div>
  </div>
  <!-- end page title -->
</div> <!-- container-fluid -->
