<div class="container-fluid">
  <!-- start page title -->
  <div class="row">
    <div class="col-xl">
      <div class="card">
        <div class=" post-content">
          <div class="user">
            <user-avatar avatarImg="assets/images/home/user.png" avatarOpentowork="true"></user-avatar>
          </div>
          <div class="form-group">
            <textarea (click)="openModal(postNow)" class="form-control share-thoughts" name="" id="" cols="30" rows="2"
              placeholder="Share your thoughts"></textarea>
          </div>

          <div class="post-actions">
            <div class="row">
              <div class="col-lg-7 actions-col">
                <div class="row">
                  <div class="col-auto col-sm col-lg-min"><a href="javascript:void(0)" (click)="openModal(postNow)"><img
                        src="assets/images/icons/photo.svg" alt=""><span>Photo</span></a> </div>
                  <div class="col-auto col-sm col-lg-min"><a href="javascript:void(0)" (click)="openModal(postNow)"><img
                        src="assets/images/icons/video.svg" alt=""><span>Video</span></a> </div>
                  <div class="col-auto col-lg-min"><a href="javascript:void(0)" (click)="openModal(postNow)"><img
                        src="assets/images/icons/article.svg" alt=""><span>Write an Article</span></a> </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <post-card [isPostImg]=true [isCommented]=true> </post-card>

      <post-card [isPostVideo]=true></post-card>

      <post-card [isShared]=true></post-card>

      <post-card [isPostImg]=true></post-card>


    </div>
    <div class="col-xl-3 col-lg-7  col-md-6 right-sidebar">
      <right-sidebar [updates]="updateSts? updateSts : false"></right-sidebar>
    </div>
  </div>
  <!-- end page title -->
</div> <!-- container-fluid -->







<!-- Standard Modal -->
<ng-template #postNow role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Share your thoughts</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row post-now-heading">
      <div class="col avatar-col">
        <div class="post-avatar">
          <user-avatar avatarImg="assets/images/home/user.png" avatarOpentowork="true"></user-avatar>
        </div>
      </div>
      <div class="col">
        <div class="post-heading">
          <h4 class="user-name">
            Livins Varghese
          </h4>
          <div class="shared-to  dropdown " ngbDropdown>
            <a class="text-muted dropdown-toggle" href="javascript: void(0);" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
              <p class="mb-0 ms-2 me-2">Anyone</p>


              <svg id="noun_world_576636" xmlns="http://www.w3.org/2000/svg" width="17.524" height="17.524"
                viewBox="0 0 17.524 17.524">
                <g id="Group_2163" data-name="Group 2163">
                  <path id="Path_4174" data-name="Path 4174"
                    d="M9.369,11.593a2.142,2.142,0,0,1-.268-.016,2.321,2.321,0,0,1-1.319-.744c-.313-.292-.491-.417-.7-.411a1.982,1.982,0,0,0-.479.1,2.164,2.164,0,0,1-1.167.084c-1.251-.324-1.38-1.619-1.465-2.476-.013-.13-.025-.256-.041-.371-.012-.081-.024-.175-.649-.133a.983.983,0,0,0-.128.022,1.135,1.135,0,0,1-.925-.116,1.4,1.4,0,0,1-.386-1.281.548.548,0,1,1,1.089.12,1.349,1.349,0,0,0-.007.205l.019,0a2.188,2.188,0,0,1,.268-.041c.407-.026,1.644-.106,1.8,1.079.018.127.031.266.045.41.081.815.175,1.4.65,1.525a1.278,1.278,0,0,0,.6-.08,2.879,2.879,0,0,1,.764-.135h.01a2.034,2.034,0,0,1,1.418.684,1.518,1.518,0,0,0,.695.474.912.912,0,0,1-.06-.213.942.942,0,0,1,.332-.86c-.035-.011-.07-.024-.105-.038a1.086,1.086,0,0,1-.723-1,1.49,1.49,0,0,1,.913-1.286,1.56,1.56,0,0,1,2.029.693l.113.137a2.514,2.514,0,0,0,.429.4q-.006-.028-.012-.053a1.583,1.583,0,0,1-.032-.71,1.964,1.964,0,0,1,.749-1.047c.142-.117.29-.228.439-.34a3.381,3.381,0,0,0,.942-.9,1.214,1.214,0,0,0,.183-.622.9.9,0,0,0-.08-.279,2.317,2.317,0,0,1-.129-.41.547.547,0,1,1,1.074-.212,1.325,1.325,0,0,0,.076.225,1.823,1.823,0,0,1,.155.657,2.31,2.31,0,0,1-.335,1.2A4.311,4.311,0,0,1,13.93,7.053c-.135.1-.269.2-.4.307a1.061,1.061,0,0,0-.377.444.946.946,0,0,0,.034.238,1.452,1.452,0,0,1,0,.806.923.923,0,0,1-.71.665c-.71.13-1.42-.648-1.62-.89l-.121-.147c-.412-.5-.456-.508-.73-.386a.534.534,0,0,0-.262.251l.051.024a.778.778,0,0,0,.136.03,1.069,1.069,0,0,1,.9.626.84.84,0,0,1-.478,1.1l-.044.026a1.122,1.122,0,0,0,.194.105.781.781,0,0,1,.695.777.548.548,0,0,1-.222.429.542.542,0,0,1-.475.087,1.681,1.681,0,0,0-.614.005A4.533,4.533,0,0,1,9.369,11.593Z"
                    transform="translate(-0.827 -1.496)" fill="#707070" />
                  <path id="Path_4175" data-name="Path 4175"
                    d="M16.9,24.693a.547.547,0,0,1-.533-.671l.126-.529c.142-.587.289-1.194.371-1.786.108-.773.1-1.009-.269-1.371a1.7,1.7,0,0,1-.628-1.3,1.249,1.249,0,0,1,.547-.922,2.26,2.26,0,0,1,.2-.132c.026-.015.059-.036.085-.054a2.474,2.474,0,0,0-.194-.372c-.045-.075-.087-.145-.12-.208a.536.536,0,0,1-.062-.214.673.673,0,0,1,.089-.493,1.24,1.24,0,0,1,1.1-.422,2.416,2.416,0,0,1,1.137.319c.139.079.262.163.378.244.308.213.4.267.624.206a4.05,4.05,0,0,0,.434-.171,2.292,2.292,0,0,1,1.719-.275c.968.4.865,1.829.67,2.419a3.552,3.552,0,0,1-1.232,1.856c-.152.109-.3.2-.44.289a1.691,1.691,0,0,0-.753.7c-.633,1.277-1.5,2.617-3.162,2.876A.565.565,0,0,1,16.9,24.693Zm.831-7.365a1.268,1.268,0,0,1,.141.907,1.159,1.159,0,0,1-.589.684c-.037.023-.076.045-.11.071-.09.068-.112.114-.113.122s-.005.136.307.446a2.329,2.329,0,0,1,.582,2.3c-.068.484-.171.961-.28,1.427a4.665,4.665,0,0,0,1.5-1.961,2.7,2.7,0,0,1,1.155-1.147c.122-.076.249-.154.379-.248a2.521,2.521,0,0,0,.832-1.311c.14-.422.071-1.013-.049-1.062a2.4,2.4,0,0,0-.863.267,4.372,4.372,0,0,1-.591.226,1.672,1.672,0,0,1-1.525-.364c-.092-.063-.188-.13-.3-.193A1.357,1.357,0,0,0,17.732,17.328Z"
                    transform="translate(-7.222 -7.337)" fill="#707070" />
                  <path id="Path_4176" data-name="Path 4176"
                    d="M8.762,17.524a8.762,8.762,0,1,1,8.762-8.762A8.772,8.772,0,0,1,8.762,17.524Zm0-16.429a7.667,7.667,0,1,0,7.667,7.667A7.675,7.675,0,0,0,8.762,1.1Z"
                    fill="#707070" />
                  <path id="Path_4177" data-name="Path 4177"
                    d="M14.7,3.436c-.065,0-.13,0-.2-.007a3.2,3.2,0,0,1-1.049-.313,3.013,3.013,0,0,0-.587-.211.858.858,0,0,1-.649-.441.848.848,0,0,1-.054-.524l.028-.148a1.751,1.751,0,0,0,.044-.305V1.466a.548.548,0,0,1-.446-.973A.931.931,0,0,1,13.007.61a1.227,1.227,0,0,1,.329.9,2.257,2.257,0,0,1-.042.377,4.99,4.99,0,0,1,.6.228,2.341,2.341,0,0,0,.69.226,4.12,4.12,0,0,0,1.035-.126l.162-.033a1.1,1.1,0,0,1-.118-.631A1.038,1.038,0,0,1,16.388.7,1.275,1.275,0,0,1,17.339.8a.548.548,0,0,1-.5.976.354.354,0,0,0-.041-.018l.032.043.06.076a1.732,1.732,0,0,1,.337.564.547.547,0,0,1-.513.716,4.965,4.965,0,0,0-.88.129A5.539,5.539,0,0,1,14.7,3.436Z"
                    transform="translate(-5.236 -0.145)" fill="#707070" />
                </g>
              </svg>
              <svg id="Down_arrow_small_" data-name="Down arrow small  " xmlns="http://www.w3.org/2000/svg" width="10"
                height="10" viewBox="0 0 10 10">
                <path id="Path_36" data-name="Path 36" d="M6.7,8.1,2,3.4,3.4,2,6.7,5.3,10,2l1.4,1.4Z"
                  transform="translate(-2)" fill="#707070" />
                <rect id="Rectangle_1467" data-name="Rectangle 1467" width="10" height="10" fill="none" />
              </svg>

            </a>
            <div class="dropdown-menu dropdown-menu-start" ngbDropdownMenu>
              <a class="dropdown-item" href="javascript: void(0);">Action</a>
              <a class="dropdown-item" href="javascript: void(0);">Another action</a>
              <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
            </div>
          </div>

        </div>
      </div>
    </div>


    <div class="row">
      <div class="form-group">
        <textarea class="form-control share-thoughts" name="" id="" cols="30" rows="2"
          placeholder="Sed ut perspiciatis unde omnis iste natus?"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex">

    <div class="post-actions">
      <div class="row">
        <div class="col-lg-12 actions-col">
          <div class="row">
            <div class="col-auto"><a href="javascript:void(0)"><svg id="noun_Photo_3136005"
                  xmlns="http://www.w3.org/2000/svg" width="24.657" height="18" viewBox="0 0 24.657 18">
                  <path id="Path_3887" data-name="Path 3887"
                    d="M16.839,17.9a1.82,1.82,0,1,1-1.82,1.82A1.82,1.82,0,0,1,16.839,17.9ZM2.959,13.5H21.7a2.959,2.959,0,0,1,2.959,2.959V28.541A2.959,2.959,0,0,1,21.7,31.5H2.959A2.959,2.959,0,0,1,0,28.541V16.459A2.959,2.959,0,0,1,2.959,13.5ZM1.479,28.541a1.479,1.479,0,0,0,1.479,1.479h.229a2.1,2.1,0,0,1,.271-.74l4.924-8.549a1.3,1.3,0,0,1,2.466,0l3.925,6.778,1.048-1.763a1.345,1.345,0,0,1,2.52,0L20.466,29.3a2.106,2.106,0,0,1,.279.722H21.7a1.479,1.479,0,0,0,1.479-1.479V16.459A1.479,1.479,0,0,0,21.7,14.979H2.959a1.479,1.479,0,0,0-1.479,1.479Z"
                    transform="translate(0 -13.5)" fill="#707070" />
                </svg>
                <span>Photo</span></a> </div>
            <div class="col-auto"><a href="javascript:void(0)"><svg id="noun_Video_2567879"
                  xmlns="http://www.w3.org/2000/svg" width="25.076" height="18" viewBox="0 0 25.076 18">
                  <path id="Path_3888" data-name="Path 3888"
                    d="M44.038,40.442l-4.765,2.993c-.959.61-1.772.174-1.772-.988V36.229c0-1.133.785-1.6,1.772-.988l4.765,2.993A1.207,1.207,0,0,1,44.038,40.442Z"
                    transform="translate(-28.585 -30.361)" fill="#707070" />
                  <path id="Path_3889" data-name="Path 3889"
                    d="M24.706,18.487a5.051,5.051,0,0,0-4.136-3.911C17.887,14.325,15.2,14.2,12.5,14.2s-5.39.125-8.072.376A5.078,5.078,0,0,0,.288,18.512a32.638,32.638,0,0,0,0,9.376,5.112,5.112,0,0,0,4.136,3.936c2.682.251,5.365.376,8.072.376s5.39-.125,8.072-.376a5.078,5.078,0,0,0,4.136-3.936A34.722,34.722,0,0,0,24.706,18.487Zm-1.755,9.125a3.3,3.3,0,0,1-2.557,2.432c-2.607.251-5.29.351-7.922.351s-5.29-.125-7.9-.351a3.3,3.3,0,0,1-2.532-2.432,30.96,30.96,0,0,1,0-8.875A3.3,3.3,0,0,1,4.6,16.306c2.607-.251,5.29-.351,7.922-.351s5.29.125,7.9.351a3.3,3.3,0,0,1,2.557,2.432A31.889,31.889,0,0,1,22.951,27.612Z"
                    transform="translate(0.05 -14.2)" fill="#707070" />
                  <path id="Path_3890" data-name="Path 3890"
                    d="M44.038,38.22l-4.765-2.993c-.959-.61-1.772-.174-1.772.988v6.218c0,1.133.785,1.6,1.772.988l4.765-2.993A1.207,1.207,0,0,0,44.038,38.22Z"
                    transform="translate(-28.585 -30.346)" fill="#707070" />
                </svg>
                <span>Video</span></a> </div>
            <div class="col-auto"><a href="javascript:void(0)"><svg id="Group_2164" data-name="Group 2164"
                  xmlns="http://www.w3.org/2000/svg" width="23.023" height="18" viewBox="0 0 23.023 18">
                  <path id="Path_3899" data-name="Path 3899"
                    d="M19.167,26.924v1.114a.92.92,0,0,1-.921.921H5.262a.92.92,0,0,1-.921-.921v-10.1H18.392l2.544-3A2.779,2.779,0,0,0,18.247,12.8H5.262A2.792,2.792,0,0,0,2.5,15.562V28.038A2.761,2.761,0,0,0,5.262,30.8H18.247a2.761,2.761,0,0,0,2.762-2.762V25.131l-.654.751A4.048,4.048,0,0,1,19.167,26.924Z"
                    transform="translate(-2.5 -12.8)" fill="#707070" />
                  <path id="Path_3900" data-name="Path 3900"
                    d="M28.906,45.79l.848-.993a1.14,1.14,0,0,0-.388-.1H21.421a.921.921,0,1,0,0,1.841h7A2.668,2.668,0,0,1,28.906,45.79Z"
                    transform="translate(-16.139 -36.972)" fill="#707070" />
                  <path id="Path_3901" data-name="Path 3901"
                    d="M20.5,62.021a.92.92,0,0,0,.921.921h5.887a1.58,1.58,0,0,1,.048-.581l.315-1.26h-6.25A.92.92,0,0,0,20.5,62.021Z"
                    transform="translate(-16.139 -49.399)" fill="#707070" />
                  <path id="Path_3902" data-name="Path 3902"
                    d="M55.783,58.3l1.987-.848a3.842,3.842,0,0,0,1.042-.727L56.171,54.5a3.273,3.273,0,0,0-.533,1.139l-.509,2.083A.475.475,0,0,0,55.783,58.3Z"
                    transform="translate(-42.362 -44.398)" fill="#707070" />
                  <path id="Path_3903" data-name="Path 3903"
                    d="M71.349,23.1l-.993-.848a1.112,1.112,0,0,0-1.575.121L63.5,28.65l2.713,2.277L71.495,24.7A1.169,1.169,0,0,0,71.349,23.1Z"
                    transform="translate(-48.722 -19.759)" fill="#707070" />
                </svg>
                <span>Write
                  an Article</span></a> </div>

          </div>
        </div>
      </div>
    </div>
    <button type="button" class="btn btn-primary post-btn"
      (click)="postUpdate();modal.dismiss(); openModal(postSuccess);">Post</button>
  </div>
</ng-template>


<ng-template #postSuccess role=" document" let-modal>
  <div class="modal-header b-none">
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body centered">
    <svg xmlns="http://www.w3.org/2000/svg" width="59" height="59" viewBox="0 0 59 59" class="active">
      <circle id="Ellipse_317" data-name="Ellipse 317" cx="29.5" cy="29.5" r="29.5" fill="#44d4b4" class="svg-elem-1">
      </circle>
      <path id="Checkbox" d="M12.072,21.643,1,10.571,3.627,7.944,12.072,16.2,27.273,1,29.9,3.627Z"
        transform="translate(14 18.178)" fill="#fff" class="svg-elem-2"></path>
    </svg>


    <h5>Your post under review </h5>
    <p>we will notify you once we done please bare with us...</p>
  </div>

</ng-template>
