import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-related-connections',
  templateUrl: './related-connections.component.html',
  styleUrls: ['./related-connections.component.scss']
})
export class RelatedConnectionsComponent implements OnInit {

@Input() firstAvatar:string = "assets/images/home/avatar.png"
@Input() secondAvatar:string = "assets/images/home/avatar.png"
@Input() action:string = "do this"
@Input() relations:string = "connections"

  constructor() { }


  ngOnInit(): void {
  }

}
