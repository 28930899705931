import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../../../core/services/event.service';
import { ConfigService } from '../../../core/services/config.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  isVisible: string;
  isActive: string;
  updateSts: any[] = []

  @ViewChild('content') content;
  constructor(private modalService: NgbModal, private configService: ConfigService, private eventService: EventService) {
  }

  ngOnInit() { }

  ngAfterViewInit() {
  }
  /**
  * Open modal
  * @param content modal content
  */
  openModal(content: any) {
    this.modalService.open(content);
  }

  postUpdate() {
    const myDate = new Date()
    this.updateSts.unshift({
      id: 'idssss',
      postTime: myDate,
      title: 'Sed ut perspiciatis ue omnis pers... ',
      content: 'Sed ut perspiciatis unde omnis iste natus. '
    });
    // console.log(this.updateSts)
  }
}
