<ng-container *ngIf="!isCompany">
  <div class="card events feed-updates" *ngIf="updates[0]">
    <div class="card-body">
      <a href="javascript:void(0)" *ngFor="let u of updates | slice:0:3;">
        <svg xmlns="http://www.w3.org/2000/svg" width=" 2.666em" height="1.222em" viewBox="0 0 47.113 22">
          <g id="Group_2173" data-name="Group 2173" transform="translate(-8428.029 -1504.125)">
            <path id="Subtraction_13" data-name="Subtraction 13"
              d="M33,22H0V0H33V.109a9.092,9.092,0,0,0-6.3,4.084H5.042a.928.928,0,1,0,0,1.855H25.807A8.635,8.635,0,0,0,25.393,7.9H16.677a.928.928,0,1,0,0,1.855h8.711a8.635,8.635,0,0,0,.4,1.856H16.677a.928.928,0,1,0,0,1.854h9.994A9.083,9.083,0,0,0,33,17.6V22ZM16.677,15.323a.928.928,0,1,0,0,1.855h7.756a.929.929,0,1,0,0-1.855ZM5.042,7.9a.95.95,0,0,0-.969.928V16.25a.95.95,0,0,0,.969.928H12.8a.95.95,0,0,0,.969-.928V8.831A.95.95,0,0,0,12.8,7.9Zm6.788,7.421H6.012V9.758H11.83v5.564l0,0Z"
              transform="translate(8428.029 1504.125)" fill="#1db0f5" />
            <g id="noun_Magnifying_Glass_6983" data-name="noun_Magnifying Glass_6983"
              transform="translate(8454.911 1505.789)">
              <g id="Group_2172" data-name="Group 2172" transform="translate(12.206 11.216)">
                <path id="Path_4198" data-name="Path 4198"
                  d="M62.234,59.058l5.775,5.006a1.265,1.265,0,0,1,.127,1.78l-.206.238a1.265,1.265,0,0,1-1.78.127L60.418,61.24"
                  transform="translate(-60.418 -59.058)" fill="#1db0f5" />
              </g>
              <path id="Path_4199" data-name="Path 4199"
                d="M2.358,13.225A7.516,7.516,0,1,1,9.874,20.74,7.516,7.516,0,0,1,2.358,13.225Zm7.611,5.492a5.492,5.492,0,1,0-5.492-5.492A5.492,5.492,0,0,0,9.969,18.717Z"
                transform="translate(-2.358 -5.709)" fill="#1db0f5" />
            </g>
          </g>
        </svg>
  
        <div class="single-update">
          <h5 class="feed-title">{{u.title}}</h5>
          <p>{{u.content}}</p>
        </div>
        <span> 1 sec ago</span>
      </a>
  
    </div>
  </div>
  
  
  
  <div class="card events">
    <div class="card-body">
  
      <h5 class="title">Jobs</h5>
      <a href="" routerLink="/jobs/details" class="single-event">
        <h5>ADHD Consultant </h5>
        <p>Sed ut perspiciatis unde omnis iste natus. </p>
      </a>
      <a href="" routerLink="/jobs/details" class="single-event">
        <h5>Psychology Assistant at Texas Heal </h5>
        <p>Sed ut perspiciatis unde omnis iste natus. </p>
      </a>
      <a href="" routerLink="/jobs/details" class="single-event">
        <h5>Senior ADHD Specialist at Indian Wave </h5>
        <p>Sed ut perspiciatis unde omnis iste... </p>
      </a>
  
      <a href="" routerLink="/jobs" class="eventsmore">More Jobs <img src="assets/images/icons/arrow-right.svg"
          alt=""></a>
  
    </div>
  </div>
  
  
  <div class="card events">
    <div class="card-body">
  
      <h5 class="title">News and Events</h5>
      <a href="" routerLink="/events" class="single-event">
        <h5>Bilingualism Comes Naturally to Our Brains</h5>
        <p>Sed ut perspiciatis unde omnis iste natus. </p>
      </a>
      <a href="" routerLink="/events" class="single-event">
        <h5>Study Links Gene to Cognitive Resilience</h5>
        <p>Sed ut perspiciatis unde omnis iste. </p>
      </a>
      <a href="" routerLink="/events" class="single-event">
        <h5>Brain Reveals the Risk for Developing </h5>
        <p>Sed ut perspiciatis unde omnis iste... </p>
      </a>
  
      <a href="#" class="eventsmore">More News <img src="assets/images/icons/arrow-right.svg" alt=""></a>
  
    </div>
  </div>
  
  
  <div class="card events training">
    <div class="card-body">
  
      <h5 class="title">Trainings and Webinar</h5>
      <a href="" routerLink="/my-trainings/details" class="single-event with-play">
        <h5>Cognitive Study on Babies</h5>
        <p>Akash Kumar
  
          <span class="rating">
            <svg class="star-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
              <path id="Polygon_2" data-name="Polygon 2"
                d="M5.1,1.905a1,1,0,0,1,1.807,0l.627,1.323a1,1,0,0,0,.793.565l1.435.159A1,1,0,0,1,10.312,5.7l-1.013.9a1,1,0,0,0-.316.954l.28,1.336A1,1,0,0,1,7.807,9.966L6.478,9.243a1,1,0,0,0-.956,0l-1.329.723A1,1,0,0,1,2.736,8.882l.28-1.336A1,1,0,0,0,2.7,6.592L1.688,5.7a1,1,0,0,1,.553-1.743l1.435-.159a1,1,0,0,0,.793-.565Z"
                fill="#daa520" />
            </svg>
  
            <svg class="star-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
              <path id="Polygon_2" data-name="Polygon 2"
                d="M5.1,1.905a1,1,0,0,1,1.807,0l.627,1.323a1,1,0,0,0,.793.565l1.435.159A1,1,0,0,1,10.312,5.7l-1.013.9a1,1,0,0,0-.316.954l.28,1.336A1,1,0,0,1,7.807,9.966L6.478,9.243a1,1,0,0,0-.956,0l-1.329.723A1,1,0,0,1,2.736,8.882l.28-1.336A1,1,0,0,0,2.7,6.592L1.688,5.7a1,1,0,0,1,.553-1.743l1.435-.159a1,1,0,0,0,.793-.565Z"
                fill="#daa520" />
            </svg>
  
            <svg class="star-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
              <path id="Polygon_2" data-name="Polygon 2"
                d="M5.1,1.905a1,1,0,0,1,1.807,0l.627,1.323a1,1,0,0,0,.793.565l1.435.159A1,1,0,0,1,10.312,5.7l-1.013.9a1,1,0,0,0-.316.954l.28,1.336A1,1,0,0,1,7.807,9.966L6.478,9.243a1,1,0,0,0-.956,0l-1.329.723A1,1,0,0,1,2.736,8.882l.28-1.336A1,1,0,0,0,2.7,6.592L1.688,5.7a1,1,0,0,1,.553-1.743l1.435-.159a1,1,0,0,0,.793-.565Z"
                fill="#daa520" />
            </svg>
  
            <svg class="star-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
              <path id="Polygon_2" data-name="Polygon 2"
                d="M5.1,1.905a1,1,0,0,1,1.807,0l.627,1.323a1,1,0,0,0,.793.565l1.435.159A1,1,0,0,1,10.312,5.7l-1.013.9a1,1,0,0,0-.316.954l.28,1.336A1,1,0,0,1,7.807,9.966L6.478,9.243a1,1,0,0,0-.956,0l-1.329.723A1,1,0,0,1,2.736,8.882l.28-1.336A1,1,0,0,0,2.7,6.592L1.688,5.7a1,1,0,0,1,.553-1.743l1.435-.159a1,1,0,0,0,.793-.565Z"
                fill="#daa520" />
            </svg>
  
            <svg class="star-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
              <path id="Polygon_2" data-name="Polygon 2"
                d="M5.1,1.905a1,1,0,0,1,1.807,0l.627,1.323a1,1,0,0,0,.793.565l1.435.159A1,1,0,0,1,10.312,5.7l-1.013.9a1,1,0,0,0-.316.954l.28,1.336A1,1,0,0,1,7.807,9.966L6.478,9.243a1,1,0,0,0-.956,0l-1.329.723A1,1,0,0,1,2.736,8.882l.28-1.336A1,1,0,0,0,2.7,6.592L1.688,5.7a1,1,0,0,1,.553-1.743l1.435-.159a1,1,0,0,0,.793-.565Z"
                fill="#DBDBDB" />
            </svg>
          </span>
  
        </p>
  
        <div class="play-btn">
  
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 22">
            <g id="Group_1986" data-name="Group 1986" transform="translate(-2952 -513)">
              <path id="Rectangle_1726" data-name="Rectangle 1726"
                d="M5,1A4,4,0,0,0,1,5V17a4,4,0,0,0,4,4H21a4,4,0,0,0,4-4V5a4,4,0,0,0-4-4H5M5,0H21a5,5,0,0,1,5,5V17a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z"
                transform="translate(2952 513)" fill="#0b6ab0" />
              <path id="Polygon_1" data-name="Polygon 1"
                d="M4.624,1.593a1,1,0,0,1,1.752,0l3.809,6.925A1,1,0,0,1,9.309,10H1.691A1,1,0,0,1,.815,8.518Z"
                transform="translate(2971 518.5) rotate(90)" fill="#0b6ab0" />
            </g>
          </svg>
  
        </div>
      </a>
  
  
      <a href="" routerLink="/my-trainings/details" class="single-event">
        <h5>Positive Psychology: Resilience Skills</h5>
        <p>John Mathew
  
          <span class="rating">
            <svg class="star-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
              <path id="Polygon_2" data-name="Polygon 2"
                d="M5.1,1.905a1,1,0,0,1,1.807,0l.627,1.323a1,1,0,0,0,.793.565l1.435.159A1,1,0,0,1,10.312,5.7l-1.013.9a1,1,0,0,0-.316.954l.28,1.336A1,1,0,0,1,7.807,9.966L6.478,9.243a1,1,0,0,0-.956,0l-1.329.723A1,1,0,0,1,2.736,8.882l.28-1.336A1,1,0,0,0,2.7,6.592L1.688,5.7a1,1,0,0,1,.553-1.743l1.435-.159a1,1,0,0,0,.793-.565Z"
                fill="#daa520" />
            </svg>
  
            <svg class="star-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
              <path id="Polygon_2" data-name="Polygon 2"
                d="M5.1,1.905a1,1,0,0,1,1.807,0l.627,1.323a1,1,0,0,0,.793.565l1.435.159A1,1,0,0,1,10.312,5.7l-1.013.9a1,1,0,0,0-.316.954l.28,1.336A1,1,0,0,1,7.807,9.966L6.478,9.243a1,1,0,0,0-.956,0l-1.329.723A1,1,0,0,1,2.736,8.882l.28-1.336A1,1,0,0,0,2.7,6.592L1.688,5.7a1,1,0,0,1,.553-1.743l1.435-.159a1,1,0,0,0,.793-.565Z"
                fill="#daa520" />
            </svg>
  
            <svg class="star-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
              <path id="Polygon_2" data-name="Polygon 2"
                d="M5.1,1.905a1,1,0,0,1,1.807,0l.627,1.323a1,1,0,0,0,.793.565l1.435.159A1,1,0,0,1,10.312,5.7l-1.013.9a1,1,0,0,0-.316.954l.28,1.336A1,1,0,0,1,7.807,9.966L6.478,9.243a1,1,0,0,0-.956,0l-1.329.723A1,1,0,0,1,2.736,8.882l.28-1.336A1,1,0,0,0,2.7,6.592L1.688,5.7a1,1,0,0,1,.553-1.743l1.435-.159a1,1,0,0,0,.793-.565Z"
                fill="#daa520" />
            </svg>
  
            <svg class="star-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
              <path id="Polygon_2" data-name="Polygon 2"
                d="M5.1,1.905a1,1,0,0,1,1.807,0l.627,1.323a1,1,0,0,0,.793.565l1.435.159A1,1,0,0,1,10.312,5.7l-1.013.9a1,1,0,0,0-.316.954l.28,1.336A1,1,0,0,1,7.807,9.966L6.478,9.243a1,1,0,0,0-.956,0l-1.329.723A1,1,0,0,1,2.736,8.882l.28-1.336A1,1,0,0,0,2.7,6.592L1.688,5.7a1,1,0,0,1,.553-1.743l1.435-.159a1,1,0,0,0,.793-.565Z"
                fill="#DBDBDB" />
            </svg>
  
            <svg class="star-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
              <path id="Polygon_2" data-name="Polygon 2"
                d="M5.1,1.905a1,1,0,0,1,1.807,0l.627,1.323a1,1,0,0,0,.793.565l1.435.159A1,1,0,0,1,10.312,5.7l-1.013.9a1,1,0,0,0-.316.954l.28,1.336A1,1,0,0,1,7.807,9.966L6.478,9.243a1,1,0,0,0-.956,0l-1.329.723A1,1,0,0,1,2.736,8.882l.28-1.336A1,1,0,0,0,2.7,6.592L1.688,5.7a1,1,0,0,1,.553-1.743l1.435-.159a1,1,0,0,0,.793-.565Z"
                fill="#DBDBDB" />
            </svg>
          </span>
  
        </p>
  
  
      </a>
  
  
      <a href="" routerLink="/my-trainings/details" class="single-event with-play">
        <h5>Cognitive Study on Babies</h5>
        <p>Abraham Ali
  
          <span class="rating">
            <svg class="star-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
              <path id="Polygon_2" data-name="Polygon 2"
                d="M5.1,1.905a1,1,0,0,1,1.807,0l.627,1.323a1,1,0,0,0,.793.565l1.435.159A1,1,0,0,1,10.312,5.7l-1.013.9a1,1,0,0,0-.316.954l.28,1.336A1,1,0,0,1,7.807,9.966L6.478,9.243a1,1,0,0,0-.956,0l-1.329.723A1,1,0,0,1,2.736,8.882l.28-1.336A1,1,0,0,0,2.7,6.592L1.688,5.7a1,1,0,0,1,.553-1.743l1.435-.159a1,1,0,0,0,.793-.565Z"
                fill="#daa520" />
            </svg>
  
            <svg class="star-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
              <path id="Polygon_2" data-name="Polygon 2"
                d="M5.1,1.905a1,1,0,0,1,1.807,0l.627,1.323a1,1,0,0,0,.793.565l1.435.159A1,1,0,0,1,10.312,5.7l-1.013.9a1,1,0,0,0-.316.954l.28,1.336A1,1,0,0,1,7.807,9.966L6.478,9.243a1,1,0,0,0-.956,0l-1.329.723A1,1,0,0,1,2.736,8.882l.28-1.336A1,1,0,0,0,2.7,6.592L1.688,5.7a1,1,0,0,1,.553-1.743l1.435-.159a1,1,0,0,0,.793-.565Z"
                fill="#daa520" />
            </svg>
  
            <svg class="star-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
              <path id="Polygon_2" data-name="Polygon 2"
                d="M5.1,1.905a1,1,0,0,1,1.807,0l.627,1.323a1,1,0,0,0,.793.565l1.435.159A1,1,0,0,1,10.312,5.7l-1.013.9a1,1,0,0,0-.316.954l.28,1.336A1,1,0,0,1,7.807,9.966L6.478,9.243a1,1,0,0,0-.956,0l-1.329.723A1,1,0,0,1,2.736,8.882l.28-1.336A1,1,0,0,0,2.7,6.592L1.688,5.7a1,1,0,0,1,.553-1.743l1.435-.159a1,1,0,0,0,.793-.565Z"
                fill="#daa520" />
            </svg>
  
            <svg class="star-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
              <path id="Polygon_2" data-name="Polygon 2"
                d="M5.1,1.905a1,1,0,0,1,1.807,0l.627,1.323a1,1,0,0,0,.793.565l1.435.159A1,1,0,0,1,10.312,5.7l-1.013.9a1,1,0,0,0-.316.954l.28,1.336A1,1,0,0,1,7.807,9.966L6.478,9.243a1,1,0,0,0-.956,0l-1.329.723A1,1,0,0,1,2.736,8.882l.28-1.336A1,1,0,0,0,2.7,6.592L1.688,5.7a1,1,0,0,1,.553-1.743l1.435-.159a1,1,0,0,0,.793-.565Z"
                fill="#daa520" />
            </svg>
  
            <svg class="star-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
              <path id="Polygon_2" data-name="Polygon 2"
                d="M5.1,1.905a1,1,0,0,1,1.807,0l.627,1.323a1,1,0,0,0,.793.565l1.435.159A1,1,0,0,1,10.312,5.7l-1.013.9a1,1,0,0,0-.316.954l.28,1.336A1,1,0,0,1,7.807,9.966L6.478,9.243a1,1,0,0,0-.956,0l-1.329.723A1,1,0,0,1,2.736,8.882l.28-1.336A1,1,0,0,0,2.7,6.592L1.688,5.7a1,1,0,0,1,.553-1.743l1.435-.159a1,1,0,0,0,.793-.565Z"
                fill="#DBDBDB" />
            </svg>
          </span>
  
        </p>
  
        <div class="play-btn">
  
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 22">
            <g id="Group_1986" data-name="Group 1986" transform="translate(-2952 -513)">
              <path id="Rectangle_1726" data-name="Rectangle 1726"
                d="M5,1A4,4,0,0,0,1,5V17a4,4,0,0,0,4,4H21a4,4,0,0,0,4-4V5a4,4,0,0,0-4-4H5M5,0H21a5,5,0,0,1,5,5V17a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z"
                transform="translate(2952 513)" fill="#0b6ab0" />
              <path id="Polygon_1" data-name="Polygon 1"
                d="M4.624,1.593a1,1,0,0,1,1.752,0l3.809,6.925A1,1,0,0,1,9.309,10H1.691A1,1,0,0,1,.815,8.518Z"
                transform="translate(2971 518.5) rotate(90)" fill="#0b6ab0" />
            </g>
          </svg>
  
        </div>
      </a>
  
  
  
      <a href="#" class="eventsmore">More News <img src="assets/images/icons/arrow-right.svg" alt=""></a>
  
    </div>
  </div>
  
  <div class="card events connections">
    <div class="card-body">
  
      <h5 class="title">People you may know</h5>
  
      <div class="connection">
        <div class="media">
          <div class="user-img">
            <user-avatar avatarImg="assets/images/home/user.png" [avatarOpentowork]="true"></user-avatar>
          </div>
          <div class="media-body">
  
            <div class="name">Joseph Alex</div>
            <div class="position">Recruiter</div>
  
            <a href="#" class="btn float-end">
              Connect
            </a>
  
          </div>
        </div>
      </div>
  
      <div class="connection">
        <div class="media">
          <div class="user-img">
            <user-avatar avatarImg="assets/images/home/avatar-3.png" [avatarHiring]="true"></user-avatar>
  
          </div>
          <div class="media-body">
  
            <div class="name">Penn Jof</div>
            <div class="position">Recruiter</div>
  
            <a href="#" class="btn float-end">
              Connect
            </a>
  
          </div>
        </div>
      </div>
  
      <div class="connection">
        <div class="media">
          <div class="user-img">
            <user-avatar avatarImg="assets/images/home/avatar-2.png"></user-avatar>
  
  
          </div>
          <div class="media-body">
  
            <div class="name">Bernie Sam</div>
            <div class="position">Recruiter</div>
  
            <a href="#" class="btn float-end">
              Connect
            </a>
  
          </div>
        </div>
      </div>
  
    </div>
  </div>
  
  
  <div class="card events connections groups">
    <div class="card-body">
  
      <h5 class="title">Groups</h5>
  
      <div class="connection">
        <div class="media">
          <div class="user-img">
            <user-avatar avatarImg="assets/images/home/group-1.png" [avatarMentor]="true"></user-avatar>
  
          </div>
          <div class="media-body">
  
            <div class="name">Texas Healing</div>
            <div class="position">1000+ People</div>
  
            <a href="#" class="btn float-end">
              Join
            </a>
  
          </div>
        </div>
      </div>
  
      <div class="connection">
        <div class="media">
          <div class="user-img">
            <user-avatar avatarImg="assets/images/home/group-2.png" [avatarMentor]="false"></user-avatar>
  
          </div>
          <div class="media-body">
  
            <div class="name">Berlin Namaste</div>
            <div class="position">500 People</div>
  
            <a href="#" class="btn float-end">
              Join
            </a>
  
          </div>
        </div>
      </div>
  
      <div class="connection">
        <div class="media">
          <div class="user-img">
  
            <user-avatar avatarImg="assets/images/home/group-3.png" [avatarMentor]="false"></user-avatar>
  
  
          </div>
          <div class="media-body">
  
            <div class="name">NY Psy Dept.</div>
            <div class="position">200 People</div>
  
            <a href="#" class="btn float-end">
              Join
            </a>
  
          </div>
        </div>
      </div>
  
    </div>
  </div>
  
</ng-container>


<div class="card events connections" *ngIf="isCompany">
  <div class="card-body">

    <h5 class="title">Pending Approvals</h5>

    <div class="connection">
      <div class="media">
        <div class="user-img">
          <user-avatar avatarImg="assets/images/home/user.png" [avatarOpentowork]="true"></user-avatar>
        </div>
        <div class="media-body">

          <div class="name">Joseph Alex</div>
          <div class="position">Recruiter</div>

          <a href="#" routerLink="/profile/recruiter-profile-in-company-as-request" class="btn float-end">
            View
          </a>

        </div>
      </div>
    </div>

    <div class="connection">
      <div class="media">
        <div class="user-img">
          <user-avatar avatarImg="assets/images/home/avatar-3.png" [avatarHiring]="true"></user-avatar>

        </div>
        <div class="media-body">

          <div class="name">Penn Jof</div>
          <div class="position">Recruiter</div>

          <a href="#" routerLink="/profile/recruiter-profile-in-company-as-request" class="btn float-end">
            View
          </a>

        </div>
      </div>
    </div>

    <div class="connection">
      <div class="media">
        <div class="user-img">
          <user-avatar avatarImg="assets/images/home/avatar-2.png"></user-avatar>


        </div>
        <div class="media-body">

          <div class="name">Bernie Sam</div>
          <div class="position">Recruiter</div>

          <a href="#" routerLink="/profile/recruiter-profile-in-company-as-request" class="btn float-end">
            View
          </a>

        </div>
      </div>
    </div>

  </div>
</div>
