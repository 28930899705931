<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>

  <!--- Sidemenu -->
  <div id="sidebar-menu" class="liri-sidebar">

    <a [routerLink]="profileLink" class="profile" routerLinkActive="active">
      <div class="image ">
        <user-avatar avatarImg="assets/images/home/user.png" avatarOpentowork="true"></user-avatar>
      </div>
      <div class="details">
        <h3>My Profile</h3>
        <div class="progress-bar-area danger">
          <div class="bar">
            <ngb-progressbar [value]="40" [striped]="true" height="0.888em" type="danger"></ngb-progressbar>
          </div>
          <div class="value">40%</div>
        </div>

        <!-- <div class="progress-bar-area warning">
          <div class="bar">
            <ngb-progressbar [value]="60" [striped]="true" height="12px" type="warning"></ngb-progressbar>
          </div>
          <div class="value">60%</div>
        </div> -->

        <!-- <div class="progress-bar-area success">
          <div class="bar">
            <ngb-progressbar [value]="100" [striped]="true" height="12px" type="success"></ngb-progressbar>
          </div>
          <div class="value">100%</div>
        </div> -->

      </div>
    </a>

    <div class="visitors">
      Who viewed <br> your profile
      <div class="details">
        <span class="count">27</span>
        <span class="duration">Last Week</span>
      </div>
    </div>


    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <!-- {{menuItems}} -->
      <ng-container *ngFor="let item of menuItems">

        <li>
          <a href="javascript:void(0)" [routerLink]="item.link" routerLinkActive="active" >
            <span class="svg-icon"><img src="{{item.icon}}"></span>
            <span> {{ item.label }}</span>

          </a>

        </li>
      </ng-container>
    </ul>

    <div class="btn-area" *ngIf="onlyTherapist">
      <a href="#" class="btn"> <img src="assets/images/icons/boost.svg" alt=""> Boost Your Profile</a>
    </div>
  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->
