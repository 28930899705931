import { Component, Input, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { msgList } from 'src/app/pages/dashboards/messages/msgmodel';

@Component({
  selector: 'message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss']
})
export class MessagePopupComponent implements OnInit {

  @Input() currentThread: msgList
  minimized: Boolean = false
  msgListActive: Boolean = false
  msgCurrent: Boolean = false

  constructor(config: NgbDropdownConfig) {
  }

  ngOnInit(): void {
  }
  closeMsg(e: Boolean) {
    this.msgCurrent = false;
  }

  openMsg(currentThread: msgList) {
    this.minimized = false;
    this.msgCurrent = true;
    this.currentThread = currentThread

    // console.log( this.minimized)
  }

  unMinimize() {
    this.minimized = false;
  }
}
