<div class="card traning-card">

    <div class="card-body">
        <div class="media">
            <div class="video-thumb">
                <img src="assets/images/home/training-img.png" alt="">

                <a href="#" class="play-btn"><img src="assets/images/icons/video-play-btn.svg" alt=""></a>
            </div>

            <div class="media-body">
                <h4><a href="#" [routerLink]="['/my-trainings/details']">Bilingualism Comes Naturally to Our Brains | Study Links Gene <br>
                    to Cognitive Resilience</a></h4>
                    <span class="course-name">Course</span>  • <span class="video-name">Video</span> • <span class="duration">1.30 Hour</span> • <span class="release-date">Released 01.12.2021</span>
                    <div class="author">By, John Mathew</div>

                  <div class="views-related">
                    <span class="views">31.5k Views </span>  •  <app-related-connections *ngIf="withConnections" action="joined" firstAvatar="assets/images/home/avatar-2.png" secondAvatar="assets/images/home/avatar-3.png" ></app-related-connections>
                  </div>
                    <div class="btn-area">
                        <a class="btn join-btn ">Join</a>
                        <save-btn></save-btn>
                    </div>
            </div>

        </div>
    </div>
</div>
