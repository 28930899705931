<button class="btn primary-btn {{btnColor}} {{pad}} ">
  <div class="pre-img {{btnIconType}}  {{ml}}" *ngIf="btnIconType">

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
      *ngIf="crossIconBordered()">
      <g id="Group_2827" data-name="Group 2827" transform="translate(-14299.707 7558.707) rotate(-90)">
        <g id="Rectangle_2928" data-name="Rectangle 2928" transform="translate(7538.707 14299.707)" fill="none"
          stroke="#fff" stroke-width="2">
          <rect width="20" height="20" rx="10" stroke="none" />
          <rect x="1" y="1" width="18" height="18" rx="9" fill="none" />
        </g>
        <g id="Group_2826" data-name="Group 2826" transform="translate(21893.072 4324.707) rotate(90)">
          <path id="Path_4795" data-name="Path 4795" d="M0,0V9.519" transform="translate(9988.38 14341) rotate(45)"
            fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
          <path id="Path_4796" data-name="Path 4796" d="M0,9.518V0"
            transform="translate(9988.38 14347.73) rotate(135)" fill="none" stroke="#fff" stroke-linecap="round"
            stroke-width="2" />
        </g>
      </g>
    </svg>

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" *ngIf="tickBordered()">
      <g id="Rectangle_2926" data-name="Rectangle 2926" transform="translate(0 20) rotate(-90)" fill="none" stroke="#fff" stroke-width="2">
        <rect width="20" height="20" rx="10" stroke="none" />
        <rect x="1" y="1" width="18" height="18" rx="9" fill="none" />
      </g>
      <path id="Union_80" data-name="Union 80" d="M0,3.473,1.336,2.138,4.509,5.253,9.762,0,11.1,1.337,4.513,7.986Z" transform="translate(4.45 6.257)" fill="#fff" />
    </svg>


    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 11.034" *ngIf="tick()">
      <path id="Checkbox" d="M6.345,11.034,0,4.69,1.287,3.4,6.345,8.368,14.713,0,16,1.287Z" fill="#fff" />
    </svg>


    <svg id="Cancel" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.557 13.556" *ngIf="cross()">
      <path id="Union_3" data-name="Union 3" d="M6.778,8.011,1.232,13.557,0,12.324,5.546,6.778,0,1.232,1.232,0,6.778,5.546,12.324,0l1.233,1.232L8.011,6.778l5.546,5.546-1.233,1.233Z" fill="#fff" />
    </svg>



  </div>


  {{btnContent}}
</button>