import { Component,Input, OnInit, } from '@angular/core';

@Component({
  selector: 'post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss']
})
export class PostCardComponent implements OnInit {

  likes: number = 41
  isLiked: boolean = false
  show = false

  @Input() isCommented = false
  @Input() isPostImg = false
  @Input() isPostVideo = false
  @Input() isShared = false
  @Input() IsCommentArea = false

  constructor() { }

  ngOnInit(): void {
  }


  liked() {

    // console.log("clicked")


    this.isLiked = !this.isLiked

    this.isLiked ? this.likes++ : this.likes--

    // if (this.isLiked) {
    //   this.likes++
    // }

    // else {
    //   this.likes--
    // }

  }
}
