import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'save-btn',
  templateUrl: './save-btn.component.html',
  styleUrls: ['./save-btn.component.scss']
})
export class SaveBtnComponent implements OnInit {
  @Input() savedbtnState = false
  @Input() savedTypeText:String = ""


  constructor() { }

  ngOnInit(): void {
  }

}
