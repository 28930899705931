<div class="message-thread">

  <ng-container *ngFor="let message  of msgThread.msgComp; let i = index;let l = last;let f = first">

    <div *ngIf="f || (i>0 && message.msgDate != msgThread.msgComp[i-1].msgDate )" class="currentDate">
      {{message.msgDate}}
    </div>

    <div class="message-list">

      <div class="single-message-row">
        <div class="single-msg-body">

          <div class="avatar">
            <user-avatar [avatarImg]="message.msgFrom.userImg ? message.msgFrom.userImg: null"></user-avatar>
          </div>
          <div class="message">
            <p>
              {{message.msg}}
            </p>
          </div>

        </div>
        <div class="single-msg-info">
          <div *ngIf="l && msgThread.threadFrom === msgThread.latestMsgFrom.username" class="avatar">
            <user-avatar [avatarImg]="message.seenBy[0].userImg"></user-avatar>
          </div>
          <div class="msg-time">
            <p>{{message.sentAt}}</p>
          </div>
        </div>
      </div>

    </div>

  </ng-container>


  <div class="live-update" *ngIf="msgThread.typingStatus">
    <div class="typingdiv">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
    <p class="live-update-body">
      {{msgThread.threadTo}} typing
    </p>
  </div>

</div>
