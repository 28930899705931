<div id="layout-wrapper">

  <app-topbar   (mobileMenuButtonClicked)="onToggleMobileMenu()">
  </app-topbar>

  <app-sidebar [isCondensed]="isCondensed"></app-sidebar>

  <div class="main-content">
    <div class="page-content">
      <!-- content -->
      <router-outlet></router-outlet>
    </div>
    <!-- footer -->
    <!-- <app-footer></app-footer> -->
  </div>
</div>
<message-popup></message-popup>
