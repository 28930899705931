<div class="related">
  <div class="avatar fisrt-avatar">
    <user-avatar avatarImg="{{firstAvatar}}"></user-avatar>

  </div>
  <div class="avatar second-avatar">

    <user-avatar avatarImg="{{secondAvatar}}"></user-avatar>

  </div>

  <span class="f13"><span class=" count">1 + </span> {{relations}} <span class="action">{{action}}</span></span>
</div>
