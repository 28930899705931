<div class="message-input-section">
  <form class="message-form" #f=ngForm (ngSubmit)="onSubmit(f)" novalidate>
    <div class="form-group">
      <textarea class="form-control" name="" width="100%" name="msgInput" [(ngModel)]="draft" rows="1"
        placeholder="Write your message..." (keydown)="draftAdd($event)"></textarea>
    </div>
    <div class="btn-area">

      <div class="btn-insert">

        <button><svg xmlns="http://www.w3.org/2000/svg" width="18.326" height="13.378" viewBox="0 0 18.326 13.378">
            <path id="Path_3887" data-name="Path 3887"
              d="M12.515,16.768a1.352,1.352,0,1,1-1.352,1.352A1.352,1.352,0,0,1,12.515,16.768ZM2.2,13.5H16.127a2.2,2.2,0,0,1,2.2,2.2v8.98a2.2,2.2,0,0,1-2.2,2.2H2.2a2.2,2.2,0,0,1-2.2-2.2V15.7A2.2,2.2,0,0,1,2.2,13.5ZM1.1,24.679a1.1,1.1,0,0,0,1.1,1.1h.17a1.558,1.558,0,0,1,.2-.55l3.66-6.354a.969.969,0,0,1,1.833,0l2.918,5.038.779-1.31a1,1,0,0,1,1.873,0l1.578,2.639a1.565,1.565,0,0,1,.207.537h.709a1.1,1.1,0,0,0,1.1-1.1V15.7a1.1,1.1,0,0,0-1.1-1.1H2.2a1.1,1.1,0,0,0-1.1,1.1Z"
              transform="translate(0 -13.5)" fill="#6d6d6d" />
          </svg>
        </button>
        <button>
          <svg xmlns="http://www.w3.org/2000/svg" width="14.765" height="16.807" viewBox="0 0 14.765 16.807">
            <g id="noun_attach_3039070" transform="translate(7.173) rotate(30)">
              <g id="Group_1994" data-name="Group 1994">
                <path id="Path_4011" data-name="Path 4011"
                  d="M4.269,0A4.326,4.326,0,0,0,0,4.372v6.81a3.1,3.1,0,0,0,3.134,3.164,3.247,3.247,0,0,0,3.242-3.164V5.114A2,2,0,0,0,4.293,3.188a1.872,1.872,0,0,0-1.9,1.926V8.767a.8.8,0,0,0,1.594,0V5.114a.291.291,0,0,1,.332-.332c.219,0,.465.142.465.332v6.068a1.644,1.644,0,0,1-1.7,1.57,1.5,1.5,0,0,1-1.491-1.57V4.372A2.714,2.714,0,0,1,4.34,1.594,2.843,2.843,0,0,1,7.173,4.372v6.786a.8.8,0,0,0,1.594,0V4.372A4.474,4.474,0,0,0,4.269,0"
                  fill="#6d6d6d" fill-rule="evenodd" />
              </g>
            </g>
          </svg>
        </button>

        <button>
          <svg xmlns="http://www.w3.org/2000/svg" width="24.326" height="8.613" viewBox="0 0 24.326 8.613">
            <path id="Path_4012" data-name="Path 4012"
              d="M10.169-15.4q.042.006.042.036v2.276q0,.036-.042.036H2.6V-9.14H7.61v-.793H3.46a.032.032,0,0,1-.036-.036V-12.2q.006-.042.036-.042h6.709q.042.006.042.042v5.376q0,.036-.042.036H.042Q0-6.791,0-6.827v-8.541A.039.039,0,0,1,.042-15.4Zm3.3,0q.042.006.042.036v8.541q0,.036-.042.036H10.854q-.042,0-.042-.036v-8.541a.039.039,0,0,1,.042-.036Zm.643.036a.039.039,0,0,1,.042-.036H24.284q.042.006.042.036v2.276q0,.036-.042.036H16.716v.793h7.568q.042.006.042.036V-9.95q0,.036-.042.036H16.716v3.087a.032.032,0,0,1-.036.036H14.157q-.042,0-.042-.036Z"
              transform="translate(0 15.404)" fill="#6d6d6d" />
          </svg>
        </button>

        <button>
          <svg xmlns="http://www.w3.org/2000/svg" width="13.878" height="13.878" viewBox="0 0 13.878 13.878">
            <g id="noun_Happy_Emoji_949332" data-name="noun_Happy Emoji_949332" transform="translate(0.25 0.25)">
              <g id="Group_1995" data-name="Group 1995">
                <path id="Path_4013" data-name="Path 4013"
                  d="M6.689.836A5.853,5.853,0,1,1,.836,6.689,5.87,5.87,0,0,1,6.689.836m0-.836a6.689,6.689,0,1,0,6.689,6.689A6.682,6.682,0,0,0,6.689,0Z"
                  fill="#6d6d6d" stroke="#6d6d6d" stroke-width="0.5" />
              </g>
              <path id="Path_4014" data-name="Path 4014"
                d="M15.418,35h6.271a.418.418,0,0,1,.418.418,3.458,3.458,0,0,1-3.554,3.345A3.458,3.458,0,0,1,15,35.418.418.418,0,0,1,15.418,35Zm5.815.836H15.874a2.763,2.763,0,0,0,5.359,0Z"
                transform="translate(-11.865 -27.684)" fill="#6d6d6d" />
              <circle id="Ellipse_272" data-name="Ellipse 272" cx="0.773" cy="0.773" r="0.773"
                transform="translate(3.093 4.639)" fill="#6d6d6d" stroke="#6d6d6d" stroke-width="0.5" />
              <circle id="Ellipse_273" data-name="Ellipse 273" cx="0.773" cy="0.773" r="0.773"
                transform="translate(8.763 4.639)" fill="#6d6d6d" stroke="#6d6d6d" stroke-width="0.5" />
            </g>
          </svg>

        </button>
      </div>

      <button class="submit" type="submit" [class.active]="draftSts">Send</button>
    </div>

  </form>
</div>
