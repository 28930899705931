import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { msglist, msglist2, msglist3, msglist4, msglist5, msglist6, msglist7, msglist8 } from 'src/app/pages/dashboards/messages/msgData';
import { msgList } from 'src/app/pages/dashboards/messages/msgmodel';

@Component({
  selector: 'message-list-popup',
  templateUrl: './message-list-popup.component.html',
  styleUrls: ['./message-list-popup.component.scss']
})
export class MessageListPopupComponent implements OnInit {

  messages: msgList[] = [msglist, msglist2, msglist3, msglist4, msglist5, msglist6, msglist7, msglist8];
  @Output() currentThread = new EventEmitter<msgList>();



  selectedIndex: number = 0
  draft: String = ''
  constructor() { }

  ngOnInit(): void {
  }



  openMsg(index: number, msgThread: msgList) {
    this.selectedIndex = index;
    this.currentThread.emit(msgThread);
    this.messages[index].latestMsgUnreadStatus = false
    let viewportWidth = Math.round(window.innerWidth);
    if (viewportWidth < 768) {
      // this.msgListDiv.nativeElement.style.display = 'none'
      // this.msgSingle.nativeElement.style.display = 'block'
    }
    this.draft = null;
    // this.scrollableMsg.scrollTo({ bottom: 0, duration: 0.3 })
  }
}
