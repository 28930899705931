<div class="card post-card shared">


    
    <div class="card-body">
        <div class="post-header">
            <div class="media">
                <div class="user-img">

                    <user-avatar avatarImg="assets/images/home/avatar-4.png" avatarVerifiedGreen="true"></user-avatar>
 
                     
                </div>
                <div class="media-body">
                    <div class="user-title"><a href="javascript:void(0)" routerLink="/connections">Adit Menon</a>
                        <div class="badge blue green"><img src="assets/images/icons/liri-badge.svg" alt=""></div>
                    </div>
                    <div class="user-designation">Recruiter</div>
                    <div class="post-time">5d</div>
                </div>
            </div>

        </div>


        <div class="post-body">
            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae perspiciatis unde omnis iste natus error sit... <a href="#">See more</a></p>

            <div class="tag-area">
                <a href="#" class="tag">#psychiatry </a>
                <a href="#" class="tag">#psychology </a>
                <a href="#" class="tag">#caparison </a>
            </div>

            
                

            <div *ngIf="isPostImg" class="post-area">
                <img  class="post-img" src="assets/images/home/post.jpg" alt="">
            </div>

                <div *ngIf="isPostVideo"  class="video-media">

                    

                    <video width="100%" height="auto" controls controlsList="nodownload">
                        <source src="assets/video/video.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
             

             


        </div>
         

    </div>
</div>