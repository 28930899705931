<div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-xl">
  
        <app-search-box searchTitle="Active Recruiters " [isSelector]="false"></app-search-box>
  
        <div class="row">
          <div class="col-md-6">
            <app-connection-card name="Ananya Mathew" postion="Recruiter NY state Counil"
              avatarImg="assets/images/home/avatar-2.png" userType="Recruiter"></app-connection-card>
          </div>
          <div class="col-md-6">
            <app-connection-card name="Jenny Alex" postion="Recruiter NY state Counil"
              avatarImg="assets/images/home/avatar-3.png" userType="Recruiter"></app-connection-card>
          </div>
          <div class="col-md-6">
            <app-connection-card name="Jenny Alex" postion="Recruiter NY state Counil"
              avatarImg="assets/images/home/avatar-1.png" userType="Recruiter"></app-connection-card>
          </div>
          <div class="col-md-6">
            <app-connection-card name="Ananya Mathew" postion="Recruiter NY state Counil"
              avatarImg="assets/images/home/avatar-4.png" userType="Recruiter"></app-connection-card>
          </div>
          <div class="col-md-6">
            <app-connection-card name="Ananya Mathew" postion="Recruiter NY state Counil"
              avatarImg="assets/images/home/avatar-4.png" userType="Recruiter"></app-connection-card>
          </div>
          <div class="col-md-6">
            <app-connection-card name="Jenny Alex" postion="Recruiter NY state Counil"
              avatarImg="assets/images/home/user.png" userType="Recruiter"></app-connection-card>
          </div>
          <div class="col-md-6">
            <app-connection-card name="Jenny Alex" postion="Recruiter NY state Counil"
              avatarImg="assets/images/home/avatar-1.png" userType="Recruiter"></app-connection-card>
          </div>
          <div class="col-md-6">
            <app-connection-card name="Ananya Mathew" postion="Recruiter NY state Counil"
              avatarImg="assets/images/home/avatar-2.png" userType="Recruiter"></app-connection-card>
          </div>
          <div class="col-md-6">
            <app-connection-card userType="Recruiter"></app-connection-card>
          </div>
          <div class="col-md-6">
            <app-connection-card userType="Recruiter"></app-connection-card>
          </div>
          <div class="col-md-6">
            <app-connection-card userType="Recruiter"></app-connection-card>
          </div>
          <div class="col-md-6">
            <app-connection-card userType="Recruiter"></app-connection-card>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button class="view-more-btn">
              View More
            </button>
          </div>
        </div>
  
         
  
         
      </div>
      <div class="col-xl-3 col-lg-7  col-md-6 right-sidebar">
        <right-sidebar [isCompany]="true"></right-sidebar>
      </div>
    </div>
    <!-- end page title -->
  </div> <!-- container-fluid -->
  