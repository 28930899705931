import { msgList } from './msgmodel';
import { Component, OnInit, ViewChild, QueryList, ElementRef } from '@angular/core';
import { msglist, msglist2, msglist3, msglist4, msglist5, msglist6, msglist7 } from './msgData'
import { NgScrollbar, } from 'ngx-scrollbar';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  @ViewChild('scrollableMsg') scrollableMsg: NgScrollbar;
  @ViewChild('msgList') msgListDiv: ElementRef;
  @ViewChild('msgSingle') msgSingle: ElementRef;


  messages: msgList[] = [msglist, msglist2, msglist3, msglist4, msglist5, msglist6, msglist7];
  currentThread: msgList = this.messages[0];
  selectedIndex: number = 0
  draft: String = ''
  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    this.scrollableMsg.scrollTo({ bottom: 0, duration: 500 })
  }



  openMsg(index: number, msgThread: msgList) {
    this.selectedIndex = index;
    this.currentThread = msgThread
    this.messages[index].latestMsgUnreadStatus = false
    let viewportWidth = Math.round(window.innerWidth);
    if (viewportWidth < 768) {
      this.msgListDiv.nativeElement.style.display = 'none'
      this.msgSingle.nativeElement.style.display = 'block'
    }
    this.draft = null;
    this.scrollableMsg.scrollTo({ bottom: 0, duration: 0.3 })
  }

  diplayList() {
    let viewportWidth = Math.round(window.innerWidth);
    if (viewportWidth < 768) {
      this.msgSingle.nativeElement.style.display = 'none'
      this.msgListDiv.nativeElement.style.display = 'block'
    }
  }
}
