import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'; 

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {

  @Input() searchTitle: string = "Search Title"
  @Input() isSavedTrainings: boolean = false
  @Input() isSavedEvents: boolean = false
  @Input() isSelector: boolean = false
  @Output() selectedUserType  = new EventEmitter<string>();
  currentSort: String = 'Recently Added'


  constructor() { }

  ngOnInit(): void {
  }
  changeSort(a: string) {
    this.currentSort = a

  }

  selectUserType(a:string){
    this.selectedUserType.emit(a)
  }
}
