<div class="user-img">
  <img class="avatar" [src]="avatarImg" alt="avatar">
  <span *ngIf="avatarOpentowork" class="with-open-to-work"><img src="assets/images/home/opentowork-45.svg"
      alt=""></span>
  <span *ngIf="avatarHiring" class="with-open-to-work hire"><img src="assets/images/icons/hiring.svg" alt=""></span>
  <span *ngIf="avatarMentor" class="with-open-to-work mentor"><img src="assets/images/icons/opentomentor.svg"
      alt=""></span>
  <div *ngIf="avatarVerified" class="verified"><img src="assets/images/icons/check.svg" alt=""></div>
  <div *ngIf="avatarVerifiedGreen" class="verified green"><img src="assets/images/icons/check.svg" alt=""></div>
  <div *ngIf="isOnline || isBusy || isOffline" class="verified {{isOnline? 'online': (isBusy? 'busy' : 'offline')}}">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82 82">
      <circle id="circleIndicator" data-name="Ellipse 334" cx="41" cy="41" r="41" fill="#fff" />
    </svg>
  </div>
</div>
