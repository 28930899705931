import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'postcard-share',
  templateUrl: './postcard-with-share.component.html',
  styleUrls: ['./postcard-with-share.component.scss']
})
export class PostcardWithShareComponent implements OnInit {

  
  likes: number = 41
  isLiked: boolean = false

  
  @Input() isPostImg = false
  @Input() isPostVideo = false

  
  constructor() { }

  ngOnInit(): void {
  }


  liked() {

    // console.log("clicked")


    this.isLiked = !this.isLiked

    this.isLiked ? this.likes++ : this.likes--

    // if (this.isLiked) {
    //   this.likes++
    // }

    // else {
    //   this.likes--
    // }

  }
}
