<button class="save-btn" (click)="savedbtnState = !savedbtnState">
  <span>
    <svg *ngIf="!savedbtnState" xmlns="http://www.w3.org/2000/svg" width="12.773" height="16.346" viewBox="0 0 12.773 16.346">
      <g id="Group_2205" data-name="Group 2205" transform="translate(1 1)">
        <path id="Path_4172" data-name="Path 4172"
          d="M15.157,2.71H7.616A1.616,1.616,0,0,0,6,4.326V15.95a1.077,1.077,0,0,0,1.837.759l3.55-3.544,3.55,3.55a1.077,1.077,0,0,0,1.837-.765V4.326A1.616,1.616,0,0,0,15.157,2.71Z"
          transform="translate(-6 -2.71)" fill="none" stroke="#464646" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="2" />
      </g>
    </svg>
    <svg *ngIf="savedbtnState" xmlns="http://www.w3.org/2000/svg" width="12.773" height="16.346" viewBox="0 0 12.773 16.346">
      <g id="Group_2205" data-name="Group 2205" transform="translate(1 1)">
        <path id="Path_4172" data-name="Path 4172"
          d="M15.157,2.71H7.616A1.616,1.616,0,0,0,6,4.326V15.95a1.077,1.077,0,0,0,1.837.759l3.55-3.544,3.55,3.55a1.077,1.077,0,0,0,1.837-.765V4.326A1.616,1.616,0,0,0,15.157,2.71Z"
          transform="translate(-6 -2.71)" fill="#464646" stroke="#464646" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="2" />
      </g>
    </svg>

    <!-- <svg *ngIf="savedbtnState" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.839 15.735">
      <g id="Group_2145" data-name="Group 2145" transform="translate(-6 -2.71)">
        <path stroke="#464646" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="M16.063,2.71H7.776A1.776,1.776,0,0,0,6,4.486V17.26a1.184,1.184,0,0,0,2.019.835l3.9-3.895,3.9,3.9a1.184,1.184,0,0,0,2.019-.841V4.486A1.776,1.776,0,0,0,16.063,2.71Z" />
      </g>
    </svg> -->
     Save<span *ngIf="savedbtnState">d</span> {{savedTypeText}}</span> </button>
