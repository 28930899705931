import { Component, OnInit } from '@angular/core';
import { postReactions } from './comment.model';
@Component({
  selector: 'single-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {


  // postReaction: boolean = false
  // likeReaction: boolean = false
  // loveReaction: boolean = false
  // totalReaction: number = 0

  // // postReactions=[this.postReaction,this.likeReaction,this.loveReaction,this.totalReaction]

  postReactions:  postReactions[] = [{
    postReaction: true,
    likeReaction: true,
    loveReaction: true,
    totalReaction: 10,
  }, {
    postReaction: false,
    likeReaction: false,
    loveReaction: false,
    totalReaction: 0,
  }
    , {
    postReaction: true,
    likeReaction: true,
    loveReaction: true,
    totalReaction: 1,
  }
  ]

  constructor() { }

  ngOnInit(): void {
  }

  liked(){
    
  }

}
