import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inactive-recruiters',
  templateUrl: './inactive-recruiters.component.html',
  styleUrls: ['./inactive-recruiters.component.scss']
})
export class InactiveRecruitersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
