<div class="card search-box">
  <div class="card-body">
    <div class="row">
      <div class="col-md-4 col-sm-4 col">
        <div class="head">
          <h5>{{searchTitle}}</h5>
        </div>
      </div>
      <div class="col-md-4 col-sm-4 search-col order-2 order-sm-1">

        <div class="search">
          <form action="">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Search">
              <span class="icon search"><img src="assets/images/icons/search-icon.svg" alt=""></span>
              <span class="icon filter"><img src="assets/images/icons/filter-icon.svg" alt=""></span>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-4 col-sm-4  col-auto order-1 order-sm-2 ">
        <div class="sort">
          <span class="sort-by">Sort by:</span>
          <div class="float-end dropdown  " ngbDropdown>
            <a class="text-muted dropdown-toggle" href="javascript: void(0);" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
            {{currentSort}} <i class="mdi mdi-chevron-down"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <a class="dropdown-item" (click)="changeSort('Recently Added')" href="javascript: void(0);"> Recently
              Added </a>
            <a class="dropdown-item" (click)="changeSort('A to Z')" href="javascript: void(0);">A to Z</a>
            <a class="dropdown-item" (click)="changeSort('Z to A')" href="javascript: void(0);">Z to A</a>
          </div>
          </div>
        </div>
      </div>
    </div>


    <a routerLink="bookmarks" *ngIf="isSavedTrainings" class="btn saved"> <img src="assets/images/icons/saved-icon.svg"
        alt=""> Saved Trainings</a>


    <a routerLink="bookmarks" *ngIf="isSavedEvents" class="btn saved"> <img src="assets/images/icons/saved-icon.svg"
        alt=""> Saved Events</a>



    <form class="selector" *ngIf="isSelector" action="">
      <div class="form-group">
        <input type="radio" id="a-option" name="rtselector"  >
        <label class="upshadow clickable" for="a-option" (click)="selectUserType('Recruiter')">

          <div class="check"><img src="assets/images/icons/checkbox-check.svg" alt=""></div>

          Recruiter

        </label>


      </div>
      <div class="form-group">
        <input type="radio" id="b-option" name="rtselector">
        <label class="upshadow clickable" for="b-option" (click)="selectUserType('Therapist')">

          <div class="check"><img src="assets/images/icons/checkbox-check.svg" alt=""></div>

          Therapist

        </label>


      </div>
    </form>


  </div>
</div>
