import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-connection-card',
  templateUrl: './connection-card.component.html',
  styleUrls: ['./connection-card.component.scss']
})
export class ConnectionCardComponent implements OnInit {
 

  @Input() userType: string = "Therapist"
  @Input() name: string = "Name goes here"
  @Input() position: string = ""
  @Input() connected: Boolean = true
  @Input() avatarImg: string = "assets/images/home/avatar.png"
  @Input() isInactive: Boolean = false

  conncetSts: string = "Connect"

  constructor(public router: Router) { }

  ngOnInit(): void {
  }
  msgBtnClick() {
    if (this.connected) {
      
      this.router.navigate(['messages']);
    }
    else {

      this.conncetSts = "Pending"

     
    }
  }

  userLink(){

    if(this.userType==="Therapist" && this.connected)
    return("/profile/profile-with-connection")

    else if(this.userType==="Therapist" && !this.connected)
    return("/profile/profile-without-connection")

    else if(this.userType==="Recruiter" && this.connected &&  !this.isInactive)
    return("/profile/approved-recruiter-profile-in-company")

    else if(this.userType==="Recruiter" && !this.connected)
    return("/profile/recruiter-profile-in-company-as-request")

    else if(this.userType==="Recruiter" &&  this.isInactive)
    return("/profile/inactive-recruiter-profile-in-company")

    // else if(this.userType==="Company" )
    // return("/profile/recruiter-profile-with-connection")
 
    else
    return("/profile/profile-without-connection")
  }

}
