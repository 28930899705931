import { msgList } from './../msgmodel';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'message-list-single-message',
  templateUrl: './message-list-single.component.html',
  styleUrls: ['./message-list-single.component.scss']
})
export class MessageListSingleComponent implements OnInit {
  @Input() status: boolean = false;
  @Input() msgThread: msgList;
  constructor() { }

  ngOnInit(): void {
  }

}
