import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DashboardsRoutingModule } from './dashboards-routing.module';
import { WidgetModule } from '../../shared/widget/widget.module';

import { NgApexchartsModule } from 'ng-apexcharts';
import { NgbDropdownModule, NgbTooltipModule, NgbNavModule, NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { SimplebarAngularModule } from 'simplebar-angular';

import { DefaultComponent } from './default/default.component';
import { ConnectionsComponent } from './connections/connections.component';
import { PostCardComponent } from './post-card/post-card.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { CommentComponent } from './comment/comment.component';
import { ReplyInputComponent } from './reply-input/reply-input.component';

import { RightSidebarComponent } from './right-sidebar/right-sidebar.component';
import { PostcardWithShareComponent } from './postcard-with-share/postcard-with-share.component';
import { UIModule } from 'src/app/shared/ui/ui.module';
import { SearchBoxComponent } from './search-box/search-box.component';
import { TrainingCardComponent } from './training-card/training-card.component';
import { RelatedConnectionsComponent } from './related-connections/related-connections.component';
import { ConnectionCardComponent } from './connection-card/connection-card.component';
import { CommentAreaComponent } from './comment-area/comment-area.component';
import { ActiveRecruitersComponent } from './active-recruiters/active-recruiters.component';
import { InactiveRecruitersComponent } from './inactive-recruiters/inactive-recruiters.component'; 
 



@NgModule({
  declarations: [DefaultComponent, ConnectionsComponent, PostCardComponent, CommentComponent,
    ReplyInputComponent, RightSidebarComponent, PostcardWithShareComponent, SearchBoxComponent,
    TrainingCardComponent, RelatedConnectionsComponent, ConnectionCardComponent, CommentAreaComponent, ActiveRecruitersComponent, InactiveRecruitersComponent    ],
  imports: [
    NgbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardsRoutingModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbNavModule,
    WidgetModule,
    NgApexchartsModule,
    SimplebarAngularModule,
    PickerModule,
    UIModule,
  ],

  exports: [
    RightSidebarComponent,
    TrainingCardComponent,
    SearchBoxComponent,
    RelatedConnectionsComponent,
    CommentAreaComponent
  ]
})
export class DashboardsModule { }
