<div class="comment-tree">
    <div class="comment">
        <div class="media">
            <div class="user-img">

                <user-avatar avatarImg="assets/images/home/user.png" avatarOpentowork="true"></user-avatar>
                 
            </div>
            <div class="media-body">
                <div class="inner">
                    <div class="user-title"><a href="javascript:void(0)" routerLink="/connections">Livins Varghese</a>
                        <div class="badge blue"><img src="assets/images/icons/liri-badge.svg" alt=""></div>
                    </div>
                    <div class="user-designation">ADHD Specialist</div>
                    <div class="post-time"> <span>5d</span>
                        <div class="float-end dropdown ms-1" ngbDropdown>
                            <a class="text-muted dropdown-toggle" href="javascript: void(0);" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                <i class="mdi mdi-dots-horizontal font-size-24"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                            </div>
                        </div>
                    </div>

                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium!</p>
                </div>

                <div class="like-area">
                    <a class="text-muted me-1" href="javascript: void(0); ">Like

                        <div class="post-reactions">
                            <a href="javascript: void(0);" class="reaction"><img src="assets/images/icons/like-filled.svg" alt=""></a>
                            <a href="javascript: void(0);" class="reaction"><img src="assets/images/icons/clap.svg" alt=""></a>
                            <a href="javascript: void(0);" class="reaction care"><img src="assets/images/icons/icon-care.svg" alt=""></a>
                            <a href="javascript: void(0);" class="reaction"><img src="assets/images/icons/love.svg" alt=""></a>
                            <a href="javascript: void(0);" class="reaction"><img src="assets/images/icons/icon-idea.svg" alt=""></a>
             
                        </div>

                        
                    </a>
                    <div class="post-reactions">
                        <a href="javascript: void(0);" *ngIf="postReactions[0].postReaction" class="reaction like"><img src="assets/images/icons/like-filled.svg" alt="">
                        </a>
                        <a href="javascript: void(0);" *ngIf="postReactions[0].likeReaction" class="reaction"><img src="assets/images/icons/clap.svg" alt=""></a>
                        <a href="javascript: void(0);" *ngIf="postReactions[0].loveReaction" class="reaction"><img src="assets/images/icons/love.svg" alt=""></a>

                        <a *ngIf="postReactions[0].totalReaction" class="text-muted" href="#">{{postReactions[0].totalReaction}}</a>


                    </div> <a class="reply text-muted" href="javascript: void(0);">Reply</a>
                </div>
            </div>
        </div>


        <div class="comment replied">

            <div class="media">
                <div class="user-img"> 
                    <user-avatar avatarImg="assets/images/home/avatar-2.png" [avatarOpentowork]="false" [avatarHiring]="false" [avatarMentor]="false" [avatarVerified]="false" [avatarVerifiedGreen]="false" ></user-avatar>
                </div>
                <div class="media-body">
                    <div class="inner">
                        <div class="user-title"><a href="javascript:void(0)" routerLink="/connections">Roopa Menon</a>
                            <div class="badge blue"><img src="assets/images/icons/liri-badge.svg" alt=""></div>
                        </div>
                        <div class="user-designation">Psychologist</div>
                        <div class="post-time"> <span>5d</span>
                            <div class="float-end dropdown ms-1" ngbDropdown>
                                <a class="text-muted dropdown-toggle" href="javascript: void(0);" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                    <i class="mdi mdi-dots-horizontal font-size-24"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                    <a class="dropdown-item" href="javascript: void(0);">Action</a>
                                    <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                                    <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                                </div>
                            </div>
                        </div>

                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium!</p>
                    </div>

                    <div class="like-area">
                        <a class="text-muted me-1" href="javascript: void(0);">Like

                            <div class="post-reactions">
                                <a href="javascript: void(0);" class="reaction"><img src="assets/images/icons/like-filled.svg" alt=""></a>
                                <a href="javascript: void(0);" class="reaction"><img src="assets/images/icons/clap.svg" alt=""></a>
                                <a href="javascript: void(0);" class="reaction care"><img src="assets/images/icons/icon-care.svg" alt=""></a>
                                <a href="javascript: void(0);" class="reaction"><img src="assets/images/icons/love.svg" alt=""></a>
                                <a href="javascript: void(0);" class="reaction"><img src="assets/images/icons/icon-idea.svg" alt=""></a>
                 
                            </div>

                        </a>
                        <div class="post-reactions">
                            <a href="javascript: void(0);" *ngIf="postReactions[1].postReaction" class="reaction like"><img src="assets/images/icons/like-filled.svg" alt=""></a>
                            <a href="javascript: void(0);" *ngIf="postReactions[1].likeReaction" class="reaction"><img src="assets/images/icons/clap.svg" alt=""></a>
                            <a href="javascript: void(0);" *ngIf="postReactions[1].loveReaction" class="reaction"><img src="assets/images/icons/love.svg" alt=""></a>

                            <a *ngIf="postReactions[1].totalReaction" class="text-muted" href="#">{{postReactions[1].totalReaction}}</a>
                        </div>
                        <a class="reply text-muted" href="javascript: void(0);">Reply</a>
                    </div>
                </div>
            </div>




            <div class="comment replied">

                <div class="media">
                    <div class="user-img">
                         
                        <user-avatar avatarImg="assets/images/home/avatar-1.png" [avatarOpentowork]="false" [avatarHiring]="false" [avatarMentor]="false" [avatarVerified]="false" [avatarVerifiedGreen]="true" ></user-avatar>
                    </div>
                    <div class="media-body">
                        <div class="inner">
                            <div class="user-title"><a href="javascript:void(0)" routerLink="/connections">Fathima Hakheem</a>
                                <div class="badge blue"><img src="assets/images/icons/liri-badge.svg" alt=""></div>
                            </div>
                            <div class="user-designation">Psychologist</div>
                            <div class="post-time"> <span>5d</span>
                                <div class="float-end dropdown ms-1" ngbDropdown>
                                    <a class="text-muted dropdown-toggle" href="javascript: void(0);" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                        <i class="mdi mdi-dots-horizontal font-size-24"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                        <a class="dropdown-item" href="javascript: void(0);">Action</a>
                                        <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                                        <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                                    </div>
                                </div>
                            </div>

                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium!</p>
                        </div>

                        <div class="like-area">
                            <a class="text-muted me-1" href="javascript: void(0);">Like

                                <div class="post-reactions">
                                    <a href="javascript: void(0);" class="reaction"><img src="assets/images/icons/like-filled.svg" alt=""></a>
                                    <a href="javascript: void(0);" class="reaction"><img src="assets/images/icons/clap.svg" alt=""></a>
                                    <a href="javascript: void(0);" class="reaction care"><img src="assets/images/icons/icon-care.svg" alt=""></a>
                                    <a href="javascript: void(0);" class="reaction"><img src="assets/images/icons/love.svg" alt=""></a>
                                    <a href="javascript: void(0);" class="reaction"><img src="assets/images/icons/icon-idea.svg" alt=""></a>
                     
                                </div>


                            </a>
                            <div class="post-reactions">
                                <a href="javascript: void(0);" *ngIf="postReactions[2].postReaction" class="reaction like"><img src="assets/images/icons/like-filled.svg" alt=""></a>
                                <a href="javascript: void(0);" *ngIf="postReactions[2].likeReaction" class="reaction"><img src="assets/images/icons/clap.svg" alt=""></a>
                                <a href="javascript: void(0);" *ngIf="postReactions[2].loveReaction" class="reaction"><img src="assets/images/icons/love.svg" alt=""></a>

                                <a *ngIf="postReactions[2].totalReaction" class="text-muted" href="#">{{postReactions[2].totalReaction}}</a>
                            </div>
                            <a class="reply text-muted" href="javascript: void(0);">Reply</a>
                        </div>
                    </div>
                </div>




            </div>


        </div>

    </div>



</div>