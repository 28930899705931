import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'message-input',
  templateUrl: './message-input.component.html',
  styleUrls: ['./message-input.component.scss']
})
export class MessageInputComponent implements OnInit {
  @Input() draft: String = ''

  draftSts:boolean = false

  onSubmit(f: NgForm) {
    console.log(f.value);  // { first: '', last: '' }
    console.log(f.valid);  // false
  }

  constructor() { }

  ngOnInit(): void {
  }

  draftAdd(event){
    this.draftSts= true
    const inputValue = event.target.value;
    this.draft+inputValue
    // console.log(this.draft)
  }
}
