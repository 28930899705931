<div #msgList class="msg-list">
  <div class="card">
    <div class="msg-list-header">
      <div class="search">
        <form action="">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Search">
            <span class="icon search"><img src="assets/images/icons/search-icon.svg" alt=""></span>
            <span class="icon filter"><img src="assets/images/icons/filter-icon.svg" alt=""></span>
          </div>
        </form>
      </div>
    </div>
    <div class="card-body msg-list-body">

      <ng-scrollbar smoothScroll #scrollable track="all" pointerEventsMethod="scrollbar">
        <ng-container *ngFor="let msgThread of messages; let i = index; let f = first">
          <message-list-single-message [status]="selectedIndex === i " [msgThread]="msgThread"
            (click)="openMsg(i,msgThread)">
          </message-list-single-message>
        </ng-container>
      </ng-scrollbar>

    </div>
  </div>
</div>
