import { RouterModule } from '@angular/router';
import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgbCollapseModule, NgbDatepickerModule, NgbTimepickerModule, NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { SaveBtnComponent } from './save-btn/save-btn.component';
import { ModalComponent } from './modal/modal.component';
import { PrimaryBtnComponent } from './primary-btn/primary-btn.component';

@NgModule({
  declarations: [PagetitleComponent, UserAvatarComponent, SaveBtnComponent, ModalComponent, PrimaryBtnComponent,],
  imports: [
    CommonModule,
    FormsModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    NgbModalModule,
    RouterModule
  ],
  exports: [PagetitleComponent, UserAvatarComponent, SaveBtnComponent, ModalComponent,PrimaryBtnComponent]
})
export class UIModule { }
