import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'add-comment',
  templateUrl: './reply-input.component.html',
  styleUrls: ['./reply-input.component.scss']
})
export class ReplyInputComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
