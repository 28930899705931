import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'primary-btn',
  templateUrl: './primary-btn.component.html',
  styleUrls: ['./primary-btn.component.scss']
})
export class PrimaryBtnComponent implements OnInit {
  @Input() btnColor: String = 'blue'
  @Input() btnContent: String = 'Default'
  @Input() btnIcon: String = 'default'
  @Input() btnIconType: String = null
  @Input() pad: String = 'pad1x'
  @Input() ml: String = 'ml-1'



  constructor() { }

  ngOnInit(): void {
  }

  crossIconBordered() {
    if (this.btnIconType === 'default') {
      return true
    } else return false
  }
  tickBordered() {
    if (this.btnIconType === 'tick-bordered') {
      return true
    } else return false
  }
  tick() {
    if (this.btnIconType === 'tick') {
      return true
    } else return false
  }
  cross() {
    if (this.btnIconType === 'cross') {
      return true
    } else return false
  }

}
