<div class="add-comment">
    <div class="media">
        <div class="user-img">
            <user-avatar avatarImg="assets/images/home/user.png" avatarOpentowork="true"></user-avatar>
        </div>
        <div class="media-body">

            <div class="with-btns">
                <textarea class="form-control" name="" id="" cols="30" rows="1" placeholder="Add a comment..."></textarea>
                <a href="javascript: void(0);" class="btn emoji-btn"><img src="assets/images/icons/emoji-btn.svg" alt=""></a>
                <a btn href="javascript: void(0);" class="btn pic-btn"><img src="assets/images/icons/pic-btn.svg" alt=""></a>
            </div>

        </div>
    </div>
</div>