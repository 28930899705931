import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SimplebarAngularModule } from 'simplebar-angular';
import { NgbDropdownModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';
import { UIModule } from '../shared/ui/ui.module';
import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { FooterComponent } from './footer/footer.component';
import { VerticalComponent } from './vertical/vertical.component';
import { LanguageService } from '../core/services/language.service';
import { TranslateModule } from '@ngx-translate/core';
import { AngularStickyThingsModule } from '@w11k/angular-sticky-things';
import { MessagePopupComponent } from './message-popup/message-popup.component';
import { MessageListPopupComponent } from './message-list-popup/message-list-popup.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MessageSinglePopupComponent } from './message-single-popup/message-single-popup.component';
import { MsgSharedModule } from '../pages/dashboards/messages/msg-shared/msg-shared.module';
@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    LayoutComponent,
    SidebarComponent,
    TopbarComponent,
    FooterComponent,
    VerticalComponent,
    MessagePopupComponent,
    MessageListPopupComponent,
    MessageSinglePopupComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgbDropdownModule,
    NgScrollbarModule,
    ClickOutsideModule,
    UIModule,
    SimplebarAngularModule,
    MsgSharedModule,
    NgbProgressbarModule,
    AngularStickyThingsModule,
  ],
  providers: [LanguageService]
})
export class LayoutsModule { }
