import { msgList } from './../msgmodel';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'message-thread',
  templateUrl: './message-thread.component.html',
  styleUrls: ['./message-thread.component.scss']
})
export class MessageThreadComponent implements OnInit {
  @Input() msgThread: msgList;

  constructor() { }

  ngOnInit(): void {
  }


  ngAfrerInit() {
  }
}
