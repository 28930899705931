import { msgList } from "./msgmodel";

export var msglist: msgList = {
  threadId: 'AAA12',
  latestMsgUnreadStatus: false,
  threadFrom: 'Livins Varghese',
  threadTo: 'Jenny Alex',
  latestMsg: "Sure I will do that.",
  typingStatus: true,
  threadtoUserImg: 'assets/images/home/avatar-5.png',
  latestMsgFrom: {
    userId: "",
    userImg: "assets/images/home/user.png",
    username: "Livins Varghese",
    userOnline: true
  },
  msgComp: [
    {
      msgId: 'AAA120000001',
      msgDate: 'Dec 25 2021',
      sentAt: '6:43PM',
      msgFrom: {
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      },
      seenBy: [
        {
          userId: "",
          userImg: "assets/images/home/user.png",
          username: "Livins Varghese",
        },
      ],
      reactions: [],
      msg: 'Can you do the presentation tomorrow?',
    },
    {
      msgId: 'AAA120000002',
      msgDate: 'Dec 25 2021',
      sentAt: '6:44PM',
      msgFrom: {
        userId: "",
        userImg: "assets/images/home/avatar-5.png",
        username: "Jenny Alex",
      },
      msg: 'Yes, but could your send me the reports?',
      seenBy: [{
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      }],
      reactions: [],
    },
    {
      msgId: 'AAA120000001',
      msgDate: 'Dec 25 2021',
      sentAt: '6:43PM',
      msgFrom: {
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      },
      seenBy: [
        {
          userId: "",
          userImg: "assets/images/home/user.png",
          username: "Livins Varghese",
        },
      ],
      reactions: [],
      msg: 'Can you do the presentation tomorrow?',
    },
    {
      msgId: 'AAA120000002',
      msgDate: 'Dec 25 2021',
      sentAt: '6:44PM',
      msgFrom: {
        userId: "",
        userImg: "assets/images/home/avatar-5.png",
        username: "Jenny Alex",
      },
      msg: 'Yes, but could your send me the reports?',
      seenBy: [{
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      }],
      reactions: [],
    },
    {
      msgId: 'AAA120000001',
      msgDate: 'Dec 25 2021',
      sentAt: '6:43PM',
      msgFrom: {
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      },
      seenBy: [
        {
          userId: "",
          userImg: "assets/images/home/user.png",
          username: "Livins Varghese",
        },
      ],
      reactions: [],
      msg: 'Can you do the presentation tomorrow?',
    },
    {
      msgId: 'AAA120000002',
      msgDate: 'Dec 25 2021',
      sentAt: '6:44PM',
      msgFrom: {
        userId: "",
        userImg: "assets/images/home/avatar-5.png",
        username: "Jenny Alex",
      },
      msg: 'Yes, but could your send me the reports?',
      seenBy: [{
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      }],
      reactions: [],
    },
    {
      msgId: 'AAA120000001',
      msgDate: 'Dec 25 2021',
      sentAt: '6:43PM',
      msgFrom: {
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      },
      seenBy: [
        {
          userId: "",
          userImg: "assets/images/home/user.png",
          username: "Livins Varghese",
        },
      ],
      reactions: [],
      msg: 'Can you do the presentation tomorrow?',
    },
    {
      msgId: 'AAA120000002',
      msgDate: 'Dec 25 2021',
      sentAt: '6:44PM',
      msgFrom: {
        userId: "",
        userImg: "assets/images/home/avatar-5.png",
        username: "Jenny Alex",
      },
      msg: 'Yes, but could your send me the reports?',
      seenBy: [{
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      }],
      reactions: [],
    },
    {
      msgId: 'AAA120000003',
      msgDate: 'Dec 25 2021',
      sentAt: '6:46PM',
      msgFrom: {
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      },
      msg: 'Sure I will do that.',
      seenBy: [{
        userId: "",
        userImg: "assets/images/home/avatar-5.png",
        username: "Jenny Alex",
      },],
      reactions: [],
    },
  ],
}


export var msglist2: msgList = {
  threadId: 'AAA12',
  latestMsgUnreadStatus: true,
  typingStatus: true,
  msgComp: [
    {
      msgId: 'AAA120000001',
      msgDate: 'Dec 25 2021',
      sentAt: '6:43PM',
      msgFrom: {
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      },
      seenBy: [
        {
          userId: "",
          userImg: "assets/images/home/user.png",
          username: "Livins Varghese",
        },
      ],
      reactions: [],

      msg: 'Can you do the presentation tomorrow?',
    },
    {
      msgId: 'AAA120000002',
      msgDate: 'Dec 25 2021',
      sentAt: '6:44PM',
      msgFrom: {
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      },
      msg: 'Yes, but could your send me the reports?',
      seenBy: [{
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      }],
      reactions: [],
    },
    {
      msgId: 'AAA120000001',
      msgDate: 'Dec 25 2021',
      sentAt: '6:43PM',
      msgFrom: {
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      },
      seenBy: [
        {
          userId: "",
          userImg: "assets/images/home/user.png",
          username: "Livins Varghese",
        },
      ],
      reactions: [],

      msg: 'Can you do the presentation tomorrow?',
    },
    {
      msgId: 'AAA120000002',
      msgDate: 'Dec 25 2021',
      sentAt: '6:44PM',
      msgFrom: {
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      },
      msg: 'Yes, but could your send me the reports?',
      seenBy: [{
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      }],
      reactions: [],
    },
    {
      msgId: 'AAA120000001',
      msgDate: 'Dec 25 2021',
      sentAt: '6:43PM',
      msgFrom: {
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      },
      seenBy: [
        {
          userId: "",
          userImg: "assets/images/home/user.png",
          username: "Livins Varghese",
        },
      ],
      reactions: [],

      msg: 'Can you do the presentation tomorrow?',
    },
    {
      msgId: 'AAA120000002',
      msgDate: 'Dec 25 2021',
      sentAt: '6:44PM',
      msgFrom: {
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      },
      msg: 'Yes, but could your send me the reports?',
      seenBy: [{
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      }],
      reactions: [],
    },
    {
      msgId: 'AAA120000001',
      msgDate: 'Dec 25 2021',
      sentAt: '6:43PM',
      msgFrom: {
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      },
      seenBy: [
        {
          userId: "",
          userImg: "assets/images/home/user.png",
          username: "Livins Varghese",
        },
      ],
      reactions: [],

      msg: 'Can you do the presentation tomorrow?',
    },
    {
      msgId: 'AAA120000002',
      msgDate: 'Dec 25 2021',
      sentAt: '6:44PM',
      msgFrom: {
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      },
      msg: 'Yes, but could your send me the reports?',
      seenBy: [{
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      }],
      reactions: [],
    },
    {
      msgId: 'AAA120000003',
      msgDate: 'Dec 25 2021',
      sentAt: '6:46PM',
      msgFrom: {
        userId: "", userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      },
      msg: 'Sure I will do that.',
      seenBy: [{
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      }, {
        userId: "",
        userImg: "assets/images/home/avatar-6.png",
        username: "Joseph Alex",
      },],
      reactions: [],
    },
    {
      msgId: 'AAA120000003',
      msgDate: 'Dec 25 2021',
      sentAt: '6:46PM',
      msgFrom: {
        userId: "",
        userImg: "assets/images/home/avatar-6.png",
        username: "Joseph Alex",
      },
      msg: ' What else we do, lets meet for a coffee tomorrow. What else we do, lets meet for a coffee tomorrow. What else we do, lets meet for a coffee tomorrow. ',
      seenBy: [{
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      }, {
        userId: "",
        userImg: "assets/images/home/avatar-6.png",
        username: "Joseph Alex",
      },],
      reactions: [],
    },
  ],
  threadFrom: 'Livins Varghese',
  threadTo: 'Joseph Alex',
  threadtoUserImg: 'assets/images/home/avatar-6.png',
  latestMsg: "What else we do, lets meet for a coffee tomorrow. What else we do, lets meet for a coffee tomorrow. What else we do, lets meet for a coffee tomorrow. ",
  latestMsgFrom: {
    userId: "",
    userImg: "assets/images/home/avatar-6.png",
    username: "Joseph Alex",
  }
}

export var msglist3: msgList = {
  threadId: 'AAA12',
  latestMsgUnreadStatus: false,
  msgComp: [
    {
      msgId: 'AAA120000001',
      msgDate: 'Dec 25 2021',
      sentAt: '6:43PM',
      msgFrom: {
        userId: "",
        userImg: "",
        username: "",
      },
      seenBy: [
        {
          userId: "",
          userImg: "assets/images/home/user.png",
          username: "Livins Varghese",
        },
      ],
      reactions: [],

      msg: 'Can you do the presentation tomorrow?',
    },
    {
      msgId: 'AAA120000002',
      msgDate: 'Dec 25 2021',
      sentAt: '6:44PM',
      msgFrom: {
        userId: "",
        userImg: "",
        username: "",
      },
      msg: 'Yes, but could your send me the reports?',
      seenBy: [{
        userId: "",
        userImg: "",
        username: "",
      }],
      reactions: [],
    },
    {
      msgId: 'AAA120000003',
      msgDate: 'Dec 25 2021',
      sentAt: '6:46PM',
      msgFrom: {
        userId: "",
        userImg: "",
        username: "",
      },
      msg: 'Ya I am free tomorrow.',
      seenBy: [{
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      }, {
        userId: "",
        userImg: "assets/images/home/avatar-6.png",
        username: "Penn Jof",
      },],
      reactions: [],
    },
  ],
  threadFrom: 'Livins Varghese',
  threadTo: 'Penn Jof',
  threadtoUserImg: 'assets/images/home/avatar-7.png',
  latestMsg: "Ya I am free tomorrow.",
  latestMsgFrom: {
    userId: "",
    userImg: "",
    username: "",
  }
}

export var msglist4: msgList = {
  threadId: 'AAA12',
  latestMsgUnreadStatus: false,
  msgComp: [
    {
      msgId: 'AAA120000001',
      msgDate: 'Dec 25 2021',
      sentAt: '6:43PM',
      msgFrom: {
        userId: "",
        userImg: "",
        username: "",
      },
      seenBy: [
        {
          userId: "",
          userImg: "assets/images/home/user.png",
          username: "Livins Varghese",
        },
      ],
      reactions: [],
      msg: 'Can you do the presentation tomorrow?',
    },
    {
      msgId: 'AAA120000002',
      msgDate: 'Dec 25 2021',
      sentAt: '6:44PM',
      msgFrom: {
        userId: "",
        userImg: "",
        username: "",
      },
      msg: 'Yes, but could your send me the reports?',
      seenBy: [{
        userId: "",
        userImg: "",
        username: "",
      }],
      reactions: [],
    },
    {
      msgId: 'AAA120000003',
      msgDate: 'Dec 26 2021',
      sentAt: '6:46PM',
      msgFrom: {
        userId: "",
        userImg: "",
        username: "",
      },
      msg: 'Ya, lets have a session and talk.',
      seenBy: [{
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      }, {
        userId: "",
        userImg: "assets/images/home/avatar-8.png",
        username: "Bernie Natalie",
      },],
      reactions: [],
    },
  ],
  threadFrom: 'Livins Varghese',
  threadTo: 'Bernie Natalie',
  threadtoUserImg: 'assets/images/home/avatar-8.png',
  latestMsg: "Ya, lets have a session and talk.",
  latestMsgFrom: {
    userId: "",
    userImg: "",
    username: "",
  }
}

export var msglist5: msgList = {
  threadId: 'AAA12',
  latestMsgUnreadStatus: false,
  msgComp: [
    {
      msgId: 'AAA120000001',
      msgDate: 'Dec 25 2021',
      sentAt: '6:43PM',
      msgFrom: {
        userId: "",
        userImg: "",
        username: "",
      },
      seenBy: [
        {
          userId: "",
          userImg: "assets/images/home/user.png",
          username: "Livins Varghese",
        },
      ],
      reactions: [],

      msg: 'Can you do the presentation tomorrow?',
    },
    {
      msgId: 'AAA120000002',
      msgDate: 'Dec 25 2021',
      sentAt: '6:44PM',
      msgFrom: {
        userId: "",
        userImg: "",
        username: "",
      },
      msg: 'Yes, but could your send me the reports?',
      seenBy: [{
        userId: "",
        userImg: "",
        username: "",
      }],
      reactions: [],
    },
    {
      msgId: 'AAA120000003',
      msgDate: 'Dec 25 2021',
      sentAt: '6:46PM',
      msgFrom: {
        userId: "",
        userImg: "",
        username: "",
      },
      msg: 'Sure I will do that.',
      seenBy: [{
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      }, {
        userId: "",
        userImg: "assets/images/home/avatar-9.png",
        username: "Margot Faur",
      },],
      reactions: [],
    },
  ],
  threadFrom: 'Livins Varghese',
  threadTo: 'Margot Faur',
  threadtoUserImg: 'assets/images/home/avatar-9.png',
  latestMsg: "Sure I will do that.",
  latestMsgFrom: {
    userId: "",
    userImg: "assets/images/home/avatar-9.png",
    username: "Margot Faur",
    userOnline: true,
  }
}

export var msglist6: msgList = {
  threadId: 'AAA12',
  latestMsgUnreadStatus: false,
  msgComp: [
    {
      msgId: 'AAA120000001',
      msgDate: 'Dec 25 2021',
      sentAt: '6:43PM',
      msgFrom: {
        userId: "",
        userImg: "",
        username: "",
      },
      seenBy: [
        {
          userId: "",
          userImg: "assets/images/home/user.png",
          username: "Livins Varghese",
        },
      ],
      reactions: [],

      msg: 'Can you do the presentation tomorrow?',
    },
    {
      msgId: 'AAA120000002',
      msgDate: 'Dec 25 2021',
      sentAt: '6:44PM',
      msgFrom: {
        userId: "",
        userImg: "",
        username: "",
      },
      msg: 'Yes, but could your send me the reports?',
      seenBy: [{
        userId: "",
        userImg: "",
        username: "",
      }],
      reactions: [],
    },
    {
      msgId: 'AAA120000003',
      msgDate: 'Dec 25 2021',
      sentAt: '6:46PM',
      msgFrom: {
        userId: "",
        userImg: "",
        username: "",
      },
      msg: 'Sure I will do that.',
      seenBy: [{
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      }, {
        userId: "",
        userImg: "assets/images/home/avatar-10.png",
        username: "Li Chian",
      },],
      reactions: [],
    },
  ],
  threadFrom: 'Livins Varghese',
  threadTo: 'Li Chian',
  threadtoUserImg: 'assets/images/home/avatar-10.png',
  latestMsg: "Ya, lets have a session and talk.",
  latestMsgFrom: {
    userId: "",
    userImg: "",
    username: "",
  }
}

export var msglist7: msgList = {
  threadId: 'AAA12',
  latestMsgUnreadStatus: false,
  msgComp: [
    {
      msgId: 'AAA120000001',
      msgDate: 'Dec 25 2021',
      sentAt: '6:43PM',
      msgFrom: {
        userId: "",
        userImg: "",
        username: "",
      },
      seenBy: [
        {
          userId: "",
          userImg: "assets/images/home/user.png",
          username: "Livins Varghese",
        },
      ],
      reactions: [],

      msg: 'Can you do the presentation tomorrow?',
    },
    {
      msgId: 'AAA120000002',
      msgDate: 'Dec 25 2021',
      sentAt: '6:44PM',
      msgFrom: {
        userId: "",
        userImg: "",
        username: "",
      },
      msg: 'Yes, but could your send me the reports?',
      seenBy: [{
        userId: "",
        userImg: "",
        username: "",
      }],
      reactions: [],
    },
    {
      msgId: 'AAA120000003',
      msgDate: 'Dec 25 2021',
      sentAt: '6:46PM',
      msgFrom: {
        userId: "",
        userImg: "",
        username: "",
      },
      msg: 'Sure I will do that.',
      seenBy: [{
        userId: "",
        userImg: "assets/images/home/user.png",
        username: "Livins Varghese",
      }, {
        userId: "",
        userImg: "assets/images/home/avatar-11.png",
        username: "Kamau Alex",
      },],
      reactions: [],
    },
  ],
  threadFrom: 'Livins Varghese',
  threadTo: 'Kamau Alex',
  threadtoUserImg: 'assets/images/home/avatar-11.png',
  latestMsg: "Ya",
  latestMsgFrom: {
    userId: "",
    userImg: "",
    username: "",
  }
}

export var msglist8: msgList = {
  threadId: 'AAA12',
  latestMsgUnreadStatus: false,
  msgComp: [

  ],
  threadFrom: 'Livins Varghese',
  threadTo: 'Kamau Alex',
  threadtoUserImg: 'assets/images/home/avatar-11.png',
  latestMsg: "Ya",
  latestMsgFrom: {
    userId: "",
    userImg: "",
    username: "",
  }
}
